import { ItemType } from '../../../types/Items'
import Item from './Item'
import { GlobalStatus } from '../../../types/GlobalStatus'
import store from '../stores'
import e from 'cors'
import { setNftVerifyState } from '../stores/UserStore'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import i18n from "../i18n"

export default class ShopDoor extends Item {
  globalStatus = GlobalStatus.OUTSIDE
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)
    //this.GlobalStatus=GlobalStatus.OUTSIDE
    this.itemType = ItemType.SHOPDOOR
  }

  onOverlapDialog() {
    const userType = store.getState().user.userType
    const videoConnected = store.getState().user.videoConnected
    const nftVerifyState = store.getState().user.nftVerifyState

    if (userType === 'Patient')
    {

      const game = phaserGame.scene.keys.game as Game



      //if (this.globalStatus==GlobalStatus.OUTSIDE){
      if (game.myPlayer.globalStatus==GlobalStatus.OUTSIDE){
      this.setDialogBox(i18n.t('game.door.appointment.enter'))     
      }else{
      this.setDialogBox(i18n.t('game.door.appointment.leave'))       
      }
    }
    else 
    {
      const game = phaserGame.scene.keys.game as Game
      
      if (!videoConnected) {
        alert(i18n.t('game.door.appointment.camera.failed'))
      }
      else if (!game.myPlayer.isInsideClinicRoom)
        store.dispatch(setNftVerifyState(2))
      else 
        this.setDialogBox(i18n.t('game.door.appointment.close'))     
      /*if (nftVerifyState === 1)
      {
        const game = phaserGame.scene.keys.game as Game
    
        game.removeClinicRoomDoor();
      }*/
    }
  }


  setGlobalStautus(doorMsgCode: number) {
    switch(doorMsgCode){
      case 0:
        this.globalStatus=GlobalStatus.OUTSIDE
      break;    
      case 1:
        this.globalStatus=GlobalStatus.INDRROOM     
      break;

      default: { 
        break; 
      }       
    }

  }




}
