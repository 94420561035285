import "reflect-metadata"
import React, { useCallback, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import { spacing } from '@mui/system'
import store from '../stores'
import { setMainScreenStateState, setUserName, setUserType, setPlayerAvatarIndex, setPlayerName } from '../stores/UserStore'
import {
  setMainDoor
} from '../stores/RoomStore'
//import { AppDataSource } from "../DB"
import { DataSource } from "typeorm"
import { User } from "../entities/user.entity"
import { deepStrictEqual } from 'assert'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useAppSelector, useAppDispatch } from '../hooks';
import { useApiRequest } from '../utils/api';
import UserAPI from '../services/api/UserAPI';

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import Bootstrap from '../scenes/Bootstrap'

import ClinicAssetAPI from '../services/api/ClinicAssetAPI';
import { phaserEvents, Event } from '../events/EventCenter'
import { useTranslation } from "react-i18next";
import MyPlayer from "../characters/MyPlayer"

const Wrapper = styled.form`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: #222639;
border-radius: 16px;
padding: 36px 60px;
box-shadow: 0px 0px 5px #0000006f;
`

const Title = styled.p`
margin: 5px;
font-size: 20px;
color: #c2c2c2;
text-align: center;
`


const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }
`

const Right = styled.div`
  width: 300px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
/*
const AppDataSource = new DataSource({
    type: "mssql",
    host: "mcc-test.crmewxldsxyk.ap-southeast-1.rds.amazonaws.com",//process.env.REACT_APP_DB_HOST,
    port: 3306,
    username: "mediconcen", //process.env.REACT_APP_DB_USERNAME,
    password: "e9b9e9630525c83a73bfb074c17f2428",//process.env.REACT_APP_DB_PASSWORD,
    database: "mccbox_develop", //process.env.REACT_APP_DB_NAME,
    entities: [User],
    synchronize: false,
    logging: false,
  })*/

export default function DoctorLoginDialog() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const [name, setName] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [alertContent, setAlertContent] = useState('')
    const [nameFieldEmpty, setNameFieldEmpty] = useState<boolean>(false)
    const [passwordFieldEmpty, setPasswordFieldEmpty] = useState<boolean>(false)
    const { submit: loginSubmit } = useApiRequest(UserAPI.login);
    const [open, setOpen] = React.useState(false);
    const { submit: checkAssetStatus } = useApiRequest(ClinicAssetAPI.checkAssetStatus);
    const [lobbyLoading, setLobbyLoading] = useState(false)
    const handleClose = () => {
      setOpen(false);
    };

  const handleLogin = async () => {
      setLobbyLoading(true);
      console.log("name",name)
      if (name.length == 0 || password.length == 0) {
        setAlertContent( t('login.warning'));
        setOpen(true);
      } else {
        //value="510@gmail.com"
        const resp = await loginSubmit({
          userName: name, password: password
          //userName: "0510@gmail.com", password: "123456"
        });

        console.log(resp);

        if (resp.data.code) {
          setAlertContent(resp.data.message);
          setOpen(true);
        }
        else {
          console.log("resp.data", resp.data);

          store.dispatch(setUserName(resp.data.userName));
          store.dispatch(setPlayerName(resp.data.displayName));
          store.dispatch(setUserType(resp.data.userType));


          // const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
          // bootstrap.network
          //   .joinOrCreatePublic()
          //   .then(() => bootstrap.launchGame(false, "Doctor"))
          //   .catch((error) => console.error(error));

            

          //game.setMyPlayerType("Doctor")
            

          const avatars = [
            { name: 'doctor' },
            { name: 'doctor2' }
          ]

          var i = 0;

          console.log("resp.data.avatarName: ", resp.data.avatarName);

          // for (let j = 0; j < avatars.length; j++) {
          //   console.log("avatars[j].name: ", avatars[j].name);
          //   if (avatars[j].name === resp.data.avatarName)
          //     i = j;
          // }
          console.log("i: ", i);

          store.dispatch(setPlayerAvatarIndex(i));


          const caresp = await checkAssetStatus({
            clinicId: resp.data.clinicId,
            assetType: 'MAIN_DOOR'
          });

          console.log("caresp.data in DoctorLogin Dialog", caresp.data);
          if (!lobbyLoading) {
            if (caresp.data.assetStatus === 0) {
              const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
              bootstrap.network
                .joinOrCreatePublic()
                .then(() => {
                  bootstrap.launchGame(true, "Doctor")
                  const game = phaserGame.scene.keys.game as Game
        
                  game.removeMainDoor();
                })
                .catch((error) => console.error(error))
            }
            else {
              const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
              bootstrap.network
                .joinOrCreatePublic()
                .then(() => {
                  bootstrap.launchGame(false, "Doctor");
                })
                .catch((error) => console.error(error))
            }

            //game.setMyPlayerType("Doctor")

            store.dispatch(setMainScreenStateState(0));
          }
        }
      }
    }

    const handleBack = () => {
        store.dispatch(setMainScreenStateState(0));
    }

    return (
        <Wrapper>
          <Title>{ t('login.title')}</Title>
          <Box sx={{ m: 3 }} />
            <TextField
                autoFocus
                fullWidth
                label={t('login.username')}
                variant="outlined"
                color="secondary"
                error={nameFieldEmpty}
                helperText={nameFieldEmpty && t('login.warningUsername')}
                style ={{width: '250px'}}
                onInput={(e) => {
                    setName((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                autoFocus
                fullWidth
                label={t('login.password')}
                variant="outlined"
                color="secondary"
                type="password"
                error={passwordFieldEmpty}
                helperText={passwordFieldEmpty && t('login.warningPassword')}
                style ={{width: '250px'}}
                onInput={(e) => {
                    setPassword((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <Bottom>
                <Button variant="contained" color="secondary" size="large" onClick={() => handleLogin()}>
                { t('login.submit')}
                </Button>
                <Box sx={{ mr: 2 }} />
                <Button variant="contained" color="secondary" size="large" onClick={() => handleBack()}>
                { t('login.back')}
                </Button>
            </Bottom>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {alertContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                { t('login.ok')}
                </Button>
              </DialogActions>
            </Dialog>
          
        </Wrapper>
    )
}