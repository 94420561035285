//import "reflect-metadata"
import React from 'react'
import styled from 'styled-components'

import { useAppSelector, useAppDispatch } from './hooks'

import RoomSelectionDialog from './components/RoomSelectionDialog'
import LoginDialog from './components/LoginDialog'
import DoctorLoginDialog from './components/DoctorLoginDialog'
import RegistrationDialog from './components/RegistrationDialog'
import ForgotPasswordDialog from './components/ForgotPasswordDialog'
import ResetPasswordDialog from './components/ResetPasswordDialog'
import VerifyNFTDialog from './components/VerifyNFTDialog'
import VendingBookDialog from './components/VendingBookDialog'
import DoctorMenuDialog from './components/DoctorMenuDialog'
import PatientDoorDialog from './components/PatientDoorDialog'
import OpenDoorDialog from './components/OpenDoorDialog'
import ComputerDialog from './components/ComputerDialog'
import WhiteboardDialog from './components/WhiteboardDialog'
import VideoConnectionDialog from './components/VideoConnectionDialog'
import Chat from './components/Chat'
import HelperButtonGroup from './components/HelperButtonGroup'
import queryString from 'query-string'
//import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers"
import { isMobile } from 'react-device-detect';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import en from './locales/en.json'
import zh from './locales/zh.json'
import store from './stores'
import { CLINIC_ID } from './utils/constant';
import ClinicAssetAPI from './services/api/ClinicAssetAPI';
import { useApiRequest } from './utils/api';
import phaserGame from './PhaserGame'
import Bootstrap from './scenes/Bootstrap'
import { setUserType , setLoggedIn } from './stores/UserStore'
import Game from './scenes/Game';


//import { DataSource } from "typeorm"

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

const lang = process.env.REACT_APP_DEFAULT_LANG;
console.log("lang",lang)
/*
const AppDataSource = new DataSource({
  type: "postgres",
  host: process.env.DB_HOST,
  port: 3306,
  username: process.env.DB_USERNAME,
  password: process.env.DB_PASSWORD,
  database: process.env.DB_NAME,
  //entities: [User],
  synchronize: true,
  logging: false,
})*/

function getLibrary(provider) {
  return new Web3Provider(provider);
}
/*
function getAppDataSource() {
  return AppDataSource;
}*/


function App() {
  const dispatch = useAppDispatch()
  const [connected,setConnected] = React.useState(false)
  const loggedIn = useAppSelector((state) => state.user.loggedIn)
  const mainScreenState = useAppSelector((state) => state.user.mainScreenState)
  const computerDialogOpen = useAppSelector((state) => state.computer.computerDialogOpen)
  const whiteboardDialogOpen = useAppSelector((state) => state.whiteboard.whiteboardDialogOpen)
  const videoConnected = useAppSelector((state) => state.user.videoConnected)
  const roomJoined = useAppSelector((state) => state.room.roomJoined)
  const nftVerifyState = useAppSelector((state) => state.user.nftVerifyState)
  const mobileState = useAppSelector((state) => state.user.mobileState)
  const { submit: checkAssetStatus } = useApiRequest(ClinicAssetAPI.checkAssetStatus);

  React.useEffect(() => {
    const param=queryString.parse(window.location.search);
    
    if (param.name != undefined) {
      console.log("url", param.name)
      let name = param.name
      let texture = param.texture
      
      if (connected == false) {
        setTimeout(() => {
          autoConnect(name,texture)
        }, 500);
      }
      
    }
  })

  const autoConnect = async (name,texture) => {
      setConnected(true)
      const caresp = await checkAssetStatus({
        clinicId: CLINIC_ID,
        assetType: 'MAIN_DOOR'
      });

      console.log("caresp",caresp.data)
      /*const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
      bootstrap.network
        .joinOrCreatePublic()
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error))*/

      if(caresp.data.assetStatus === 0)
      {
        const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
        dispatch(setLoggedIn(true))
        bootstrap.network
          .joinOrCreatePublic()
          .then(() => 
          {
            bootstrap.launchGame(true, "Patient")
            const game = phaserGame.scene.keys.game as Game
     
            game.removeMainDoor();

            setTimeout(() => {
              game.registerKeys()
              if (name == undefined) {
                const param = queryString.parse(window.location.search);
                let local_name = param.name as string
                let local_texture = param.texture as string
                game.myPlayer.setPlayerName(local_name)
                game.myPlayer.setPlayerTexture(local_texture)
                game.network.readyToConnect()
              } else {
                game.myPlayer.setPlayerName(name)
                game.myPlayer.setPlayerTexture(texture)
                game.network.readyToConnect()
              }
            }, 100);
            
            
            
          })
        .catch((error) => console.error(error))
      }
      else
      {
        dispatch(setLoggedIn(true))
        const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
        bootstrap.network
          .joinOrCreatePublic()
          .then(() => {
            bootstrap.launchGame(false, "Patient");
            const game = phaserGame.scene.keys.game as Game

            setTimeout(() => {
              game.registerKeys()
              game.myPlayer.setPlayerName(name)
              game.myPlayer.setPlayerTexture(texture)
              game.network.readyToConnect()
            }, 50);

            
            
            
          })
          .catch((error) => console.error(error))
      }

      store.dispatch(setUserType('Patient'));
  }

  console.log('loggedIn: ', loggedIn);
  console.log('mainScreenState: ', mainScreenState);
  console.log('computerDialogOpen: ', computerDialogOpen);
  console.log('whiteboardDialogOpen: ', whiteboardDialogOpen);
  console.log('videoConnected: ', videoConnected);
  console.log('roomJoined: ', roomJoined);
  console.log('nftVerifyState: ', nftVerifyState);
  
  //AppDataSource..manager.save(user)

  let ui: JSX.Element
  //let nftVerifyPopup: JSX.Element

  if (loggedIn) {
    if (computerDialogOpen) {
      /* Render ComputerDialog if user is using a computer. */
      ui = <ComputerDialog />
    } else if (whiteboardDialogOpen) {
      /* Render WhiteboardDialog if user is using a whiteboard. */
      ui = <WhiteboardDialog />
    } else {
      ui = (
        /* Render Chat or VideoConnectionDialog if no dialogs are opened. */
        <>
          <Chat />
          {/* Render VideoConnectionDialog if user is not connected to a webcam. */}
          {!videoConnected && <VideoConnectionDialog />}
        </>
      )
    }
  } else if (roomJoined) {
    /* Render LoginDialog if not logged in but selected a room. */
    ui = <LoginDialog />
  } else {
    /* Render RoomSelectionDialog if yet selected a room. */
    ui = <RoomSelectionDialog />
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Backdrop>
        {mainScreenState === 0 && ui}
        {mainScreenState === 1 && <DoctorLoginDialog />}
        {mainScreenState === 2 && <RegistrationDialog />}
        {mainScreenState === 3 && <ForgotPasswordDialog />}
        {mainScreenState === 4 && <ResetPasswordDialog />}
        {nftVerifyState === 2 && <VerifyNFTDialog />}
        {(mobileState === 1 && isMobile)&& <VendingBookDialog />}
        {(mobileState === 2 && isMobile && nftVerifyState != 2 && nftVerifyState != 1) && <OpenDoorDialog />}
        {(mobileState === 3 && isMobile) && <DoctorMenuDialog />}
        {(mobileState === 4 && isMobile) && <PatientDoorDialog />}
        {/* Render HelperButtonGroup if no dialogs are opened. */}
        {!computerDialogOpen && !whiteboardDialogOpen && <HelperButtonGroup />}
      </Backdrop>
    </Web3ReactProvider>
  )
}

export default App
