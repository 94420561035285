import Peer from 'peerjs'
import Network from '../services/Network'
import store from '../stores'
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import { setVideoConnected, setPlayerLang, setUserType, setUserName } from '../stores/UserStore'
import i18n from "../i18n"
import Bootstrap from '../scenes/Bootstrap'
import { phaserEvents, Event } from '../events/EventCenter'

import iconAudioOff  from '../assets/icon/icon-audio-off.png'
import iconAudioOn from '../assets/icon/icon-audio-on.png'
import iconVideoOff from '../assets/icon/icon-video-off.png'
import iconVideoOn from '../assets/icon/icon-video-on.png'
import iconLangEn from '../assets/icon/icon-lang-en.png'
import iconLangZh from '../assets/icon/icon-lang-zh.png'
import iconExit from '../assets/icon/icon-exit.png'
import { isFirefox, isSafari, isMobile } from 'react-device-detect'



export default class WebRTC {
  private myPeer: Peer
  private peers = new Map<string, { call: Peer.MediaConnection; video: HTMLVideoElement }>()
  private onCalledPeers = new Map<string, { call: Peer.MediaConnection; video: HTMLVideoElement }>()
  private videoGrid = document.querySelector('.video-grid')
  private videoGrid2 = document.querySelector('.video-grid-hidden')
  private buttonGrid = document.querySelector('.button-grid')
  private myVideo = document.createElement('video') 
  private myStream?: MediaStream
  private network: Network
  private videoCounter= 0
  private meetingDr = false
  private videoOpen = false
  private buttonCreated = false;
  private game = phaserGame.scene.keys.game as Game
  private bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
  private userId;
  constructor(userId: string, network: Network) {
    const sanitizedId = this.replaceInvalidId(userId)
    this.myPeer = new Peer(sanitizedId)
    this.network = network
    console.log('userId:', userId)
    this.userId = userId;
    console.log('sanitizedId:', sanitizedId)
    this.myPeer.on('error', (err) => {
      console.log(err.type)
      console.error(err)
    })

    // mute your own video stream (you don't want to hear yourself)
    this.myVideo.muted = true
    

    // config peerJS
    this.initialize()
  }

  // PeerJS throws invalid_id error if it contains some characters such as that colyseus generates.
  // https://peerjs.com/docs.html#peer-id
  private replaceInvalidId(userId: string) {
    return userId.replace(/[^0-9a-z]/gi, 'G')
  }

  initialize() {

    this.myPeer.on('call', (call) => {
      if (!this.onCalledPeers.has(call.peer)) {
        call.answer(this.myStream)
        const video = document.createElement('video')
        this.onCalledPeers.set(call.peer, { call, video })

        call.on('stream', (userVideoStream) => {
          if (isSafari && isMobile) {
            alert(i18n.t('game.video.consultation'));
          }
          this.addVideoStream(video, userVideoStream)
        })
      }
      // on close is triggered manually with deleteOnCalledVideoStream()
    })
  }

  // check if permission has been granted before
  checkPreviousPermission() {
    if (isFirefox) { //check if firefox then we dont need to check navigator permission and straight ask video permission
      try {
        this.setUpNoVideoButtons();
        this.getUserMedia(true);
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        this.setUpNoVideoButtons();//in case it fail asking permissionn setup this button
        const permissionName = 'microphone' as PermissionName
        navigator.permissions?.query({ name: permissionName }).then((result) => {
          if (result.state === 'granted') this.getUserMedia(false)
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  getUserMedia(alertOnError = true) {
    // ask the browser to get user media
    navigator.mediaDevices
      ?.getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        this.myStream = stream
        this.myStream.getAudioTracks()[0].enabled = false;
        this.addInitialVideoStream(this.myVideo, this.myStream)
        this.setUpButtons()
        store.dispatch(setVideoConnected(true))
        this.network.videoConnected()
      })
      .catch((error) => {
        if (alertOnError) window.alert('No webcam or microphone found, or permission is blocked')
      })
  }

  // method to call a peer
  connectToNewUser(userId: string) {
    if (this.myStream) {
      const sanitizedId = this.replaceInvalidId(userId)
      if (!this.peers.has(sanitizedId)) {
        console.log('calling', sanitizedId)
        const call = this.myPeer.call(sanitizedId, this.myStream)
        const video = document.createElement('video')
        this.peers.set(sanitizedId, { call, video })

        call.on('stream', (userVideoStream) => {
          if (isSafari && isMobile) {
            alert(i18n.t('game.video.consultation'));
          }
          this.addVideoStream(video, userVideoStream)
        })

        // on close is triggered manually with deleteVideoStream()
      }
    }
  }

  // method to add new video stream to videoGrid div
  addInitialVideoStream(video: HTMLVideoElement, stream: MediaStream) {
      video.setAttribute("muted", "");
      video.setAttribute("playsinline", "");
      video.srcObject = stream
      video.addEventListener('loadedmetadata', () => {
        video.play()
      })

    //*--This is to check if there is >1 ppl talking. If yes hen change video-grid to video-grid2--*
    //*--Next setp should get the Mplayer global status to determine meetingDr= true instead--*
        this.meetingDr=false
        this.videoCounter=this.videoCounter+1

        console.log("videoCounter: "+this.videoCounter)

        if (this.videoCounter>1) this.meetingDr=true

        if (this.videoGrid && this.meetingDr){

          //this.videoGrid.classList.replace('video-grid', 'video-grid');
          //this.videoGrid.append(video)
          this.videoGrid2.classList.replace('video-grid-hidden', 'video-grid2'); //add the video to the second video grid 
          this.videoGrid2.append(video)
          this.meetingDr=false
        }else {

          if (this.videoGrid) this.videoGrid.append(video)
        }

  }
  

  addVideoStream(video: HTMLVideoElement, stream: MediaStream) {
    //if (isSafari && isMobile) {
    video.setAttribute("muted", "");
    video.setAttribute("playsinline", "");
    //}
    video.srcObject = stream
    video.addEventListener('loadedmetadata', () => {
      video.play()
    })

  //*--This is to check if there is >1 ppl talking. If yes hen change video-grid to video-grid2--*
  //*--Next setp should get the Mplayer global status to determine meetingDr= true instead--*
      this.meetingDr=false
      this.videoCounter=this.videoCounter+1

      console.log("videoCounter: "+this.videoCounter)

      if (this.videoCounter>1) this.meetingDr=true

      if (this.videoGrid && this.meetingDr){

        //this.videoGrid.classList.replace('video-grid', 'video-grid');
        //this.videoGrid.append(video)
        this.videoGrid2.classList.replace('video-grid-hidden', 'video-grid2'); //add the video to the second video grid 
        this.videoGrid2.append(video)
        this.meetingDr=false
      }else {

        if (this.videoGrid) this.videoGrid.append(video)
      }

}

  removeVideoStream() {
    if (this.myStream) { // do checking so it will only remove if it has video permission
      this.myStream.getTracks().forEach(function (track) {
        if (track.readyState == 'live') {
          track.stop();
        }
      });
    }
    this.buttonGrid.replaceChildren();
    this.videoGrid.replaceChildren();
}

  // method to remove video stream (when we are the host of the call)
  deleteVideoStream(userId: string) {
    //**--Turn back the video grid style back to normal (i.e small one)
    if (this.videoGrid2) this.videoGrid2.classList.replace('video-grid2', 'video-grid-hidden');
    this.videoCounter=0

    const sanitizedId = this.replaceInvalidId(userId)
    if (this.peers.has(sanitizedId)) {
      let peer = this.peers.get(sanitizedId)
      peer?.call.close()
      peer?.video.remove()
      peer = null;
      this.peers.delete(sanitizedId)
    }
  }

  // method to remove video stream (when we are the guest of the call)
  deleteOnCalledVideoStream(userId: string) {
    console.log(userId)
    //**--Turn back the video grid style back to normal (i.e small one)
    if (this.videoGrid2) this.videoGrid2.classList.replace('video-grid2', 'video-grid-hidden');
    this.videoCounter=0

    const sanitizedId = this.replaceInvalidId(userId)
    if (this.onCalledPeers.has(sanitizedId)) {
      let onCalledPeer = this.onCalledPeers.get(sanitizedId)
      onCalledPeer?.call.close()
      onCalledPeer?.video.remove()
      onCalledPeer = null;
      this.onCalledPeers.delete(sanitizedId)
    }
  }

  // method to set up mute/unmute and video on/off buttons
  setUpButtons() {
    const videoElement = this.videoGrid.querySelector('video');
    

    
    this.buttonGrid?.replaceChildren();// remove all
    const audioButton = document.createElement("div");
    audioButton.classList.add('tooltip');

    const audioButtonImage = document.createElement('img')
    audioButtonImage.setAttribute('src', iconAudioOff)
    const audioButtonCaption = document.createElement('span')
    audioButtonCaption.classList.add('tooltiptext')
    audioButtonCaption.innerText = i18n.t("game.audio.mute");

    audioButton.appendChild(audioButtonImage)
    audioButton.appendChild(audioButtonCaption)
    const audioTrack = this.myStream.getAudioTracks()[0]
    audioButton.addEventListener('click', () => {
      if (this.myStream) {
        
        if (audioTrack.enabled) {
            if (this.videoCounter>1) {
              audioTrack.enabled = false
              audioButtonImage.setAttribute('src', iconAudioOff)
              audioButtonCaption.innerText = i18n.t("game.audio.unmute");
            }
          
        } else {
          if (this.videoCounter > 1) {
              const vidGrid2 = document.querySelector('.video-grid2')
              const videoElement2 = vidGrid2.querySelector('video');
              console.log("video grid",vidGrid2)
              videoElement.removeAttribute('muted')
              videoElement2.removeAttribute('muted')
              audioTrack.enabled = true
              audioButtonImage.setAttribute('src', iconAudioOn)
              audioButtonCaption.innerText = i18n.t("game.audio.mute");
            }
          
        }
      }
    })
    const videoButton = document.createElement('div')
    videoButton.classList.add('tooltip');

    const videoButtonImage = document.createElement('img')
    videoButtonImage.setAttribute('src', iconVideoOn)
    const videoButtonCaption = document.createElement('span')
    videoButtonCaption.classList.add('tooltiptext')
    videoButtonCaption.innerText = i18n.t("game.video.off");

    videoButton.appendChild(videoButtonImage)
    videoButton.appendChild(videoButtonCaption)
    
    const videoTrack = this.myStream.getVideoTracks()[0]

    videoButton.addEventListener('click', () => {
      if (this.myStream) {
        if (videoTrack.enabled) {
          videoTrack.enabled = false
          videoButtonImage.setAttribute('src', iconVideoOff)
          videoButtonCaption.innerText = i18n.t("game.video.on");
        } else {
          videoTrack.enabled = true
          videoButtonImage.setAttribute('src', iconVideoOn)
          videoButtonCaption.innerText = i18n.t("game.video.off");
        }
      }
    })

    const langDefault = store.getState().user.playerLang;
    const lngButton = document.createElement('div')
    lngButton.classList.add('tooltip');

    const lngButtonImage = document.createElement('img')
    
    const lngButtonCaption = document.createElement('span')
    lngButtonCaption.classList.add('tooltiptext')
    if (langDefault == "zh") {
      lngButtonCaption.innerText = "ENG";
      lngButtonImage.setAttribute('src', iconLangZh)
    } else {
      lngButtonCaption.innerText = "中文";
      lngButtonImage.setAttribute('src', iconLangEn)
    }
    
    lngButton.appendChild(lngButtonImage)
    lngButton.appendChild(lngButtonCaption)
    
    const logoutButton = document.createElement('div')
    logoutButton.classList.add('tooltip');
    logoutButton.setAttribute('src', iconExit)

    const logoutButtonImage = document.createElement('img')
    logoutButtonImage.setAttribute('src', iconExit)
    const logoutButtonCaption = document.createElement('span')
    logoutButtonCaption.classList.add('tooltiptext')
    logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")

    logoutButton.appendChild(logoutButtonImage)
    logoutButton.appendChild(logoutButtonCaption)

    logoutButton.addEventListener('click', () => {
      this.logout()
    })

    lngButton.addEventListener('click', () => {
      const langDefaults = store.getState().user.playerLang;
      console.log("i18n",langDefaults)
      if (langDefaults == "zh") {
        lngButtonImage.setAttribute('src', iconLangEn)
        store.dispatch(setPlayerLang('en'))
        i18n.changeLanguage("en")
        lngButtonCaption.innerText = "ENG";
        if (audioTrack.enabled) {
          audioButtonCaption.innerText = i18n.t("game.audio.unmute");
        } else {
          audioButtonCaption.innerText = i18n.t("game.audio.mute");
        }
        if (videoTrack.enabled) {
          videoButtonCaption.innerText = i18n.t("game.video.on");
        } else {
          videoButtonCaption.innerText = i18n.t("game.video.off");
        }
        logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")
      } else {
        lngButtonImage.setAttribute('src', iconLangZh)
        store.dispatch(setPlayerLang('zh'))
        i18n.changeLanguage("zh")
        lngButtonCaption.innerText = "中文";
        if (audioTrack.enabled) {
          audioButtonCaption.innerText = i18n.t("game.audio.unmute");
        } else {
          audioButtonCaption.innerText = i18n.t("game.audio.mute");
        }
        if (videoTrack.enabled) {
          videoButtonCaption.innerText = i18n.t("game.video.on");
        } else {
          videoButtonCaption.innerText = i18n.t("game.video.off");
        }
        logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")
      }
    })

    this.buttonGrid?.append(videoButton)
    this.buttonGrid?.append(audioButton)
    this.buttonGrid?.append(lngButton)
    this.buttonGrid?.append(logoutButton)

  }

  setUpNoVideoButtons() {
    const lngButton = document.createElement('div')
    lngButton.classList.add('tooltip');

    const lngButtonImage = document.createElement('img')
    lngButtonImage.setAttribute('src', iconLangZh)
    const lngButtonCaption = document.createElement('span')
    lngButtonCaption.classList.add('tooltiptext')
    lngButtonCaption.innerText = "ENG";
    
    lngButton.appendChild(lngButtonImage)
    lngButton.appendChild(lngButtonCaption)
    
    lngButton.addEventListener('click', () => {
      const langDefault = store.getState().user.playerLang;
      if (langDefault == "zh") {
        lngButtonImage.setAttribute('src', iconLangEn)
        store.dispatch(setPlayerLang('en'))
        i18n.changeLanguage("en")
        lngButtonCaption.innerText = "ENG";
        logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")
      } else {
        lngButtonImage.setAttribute('src', iconLangZh)
        store.dispatch(setPlayerLang('zh'))
        i18n.changeLanguage("zh")
        lngButtonCaption.innerText = "中文";
        logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")
      }
    })

    const logoutButton = document.createElement('div')
    logoutButton.classList.add('tooltip');
    logoutButton.setAttribute('src', iconExit)

    const logoutButtonImage = document.createElement('img')
    logoutButtonImage.setAttribute('src', iconExit)
    const logoutButtonCaption = document.createElement('span')
    logoutButtonCaption.classList.add('tooltiptext')
    logoutButtonCaption.innerText = i18n.t("helperDialog.titleLogout")

    logoutButton.appendChild(logoutButtonImage)
    logoutButton.appendChild(logoutButtonCaption)

    logoutButton.addEventListener('click', () => {
      this.logout()
    })

    this.buttonGrid?.append(lngButton)
    this.buttonGrid?.append(logoutButton)
  }

  removeButtons() {
    this.buttonGrid?.replaceChildren();
  }

  logout() {
    store.dispatch(setUserType(""))
    store.dispatch(setUserName(""))
    this.removeButtons();
    this.game.resetMap()
    this.bootstrap.logout(this.game.myPlayer.playerId);
    phaserEvents.emit(Event.PLAYER_LEFT, this.game.myPlayer.playerId)
    phaserEvents.emit(Event.PLAYER_DISCONNECTED, this.game.myPlayer.playerId)
  }

  setCloseCallButton() {
    if (this.videoOpen == false) { // set up the first time
      const closeCallButton = document.createElement('button')
      closeCallButton.innerText = i18n.t('doctor.close.call')
      closeCallButton.style.color = "white";
      closeCallButton.style.height = "50px"
      closeCallButton.style.backgroundColor = "#FD1918";
      closeCallButton.style.border = "none";
      closeCallButton.style.borderRadius = "10px";
      closeCallButton.style.fontWeight = "bold";
      closeCallButton.addEventListener('click', () => {
        if (this.videoOpen == true && this.buttonCreated == true) {
          const game = phaserGame.scene.keys.game as Game
          game.closeCall()
        }
        //closeCallButton.setAttribute("display","none")
        //closeCallButton.remove()
        closeCallButton.style.display = "none";
      })
      this.videoGrid?.append(closeCallButton)

      this.videoOpen = true;
      this.buttonCreated = true;
    } else {
      const closeCallButton = this.videoGrid.querySelector('button')
      closeCallButton.innerText = i18n.t('doctor.close.call')
      closeCallButton.style.display = "block";
    }
  }
}
