import { ItemType } from '../../../types/Items'
import Item from './Item'
import store from '../stores'
import i18n from "../i18n"

export default class DoctorChair extends Item {
  itemDirection?: string

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.itemType = ItemType.DOCTORCHAIR
  }

  onOverlapDialog() {
    const userType = store.getState().user.userType

    if (userType === 'Doctor')
      this.setDialogBox(i18n.t("doctor.v"))
  }
}
