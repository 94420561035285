export enum ItemType {
  CHAIR,
  DOCTORCHAIR,
  COMPUTER,
  WHITEBOARD,
  VENDINGMACHINE,
  SHOPDOOR,
  MAINDOOR,
  EXITDOOR
}
