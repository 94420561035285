import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import { spacing } from '@mui/system'
import store from '../stores'
import { setMainScreenStateState } from '../stores/UserStore'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useApiRequest } from '../utils/api';
import UserAPI from '../services/api/UserAPI';

const Wrapper = styled.form`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: #222639;
border-radius: 16px;
padding: 36px 60px;
box-shadow: 0px 0px 5px #0000006f;
`

const Title = styled.p`
margin: 5px;
font-size: 20px;
color: #c2c2c2;
text-align: center;
`


const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }
`

const Right = styled.div`
  width: 300px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function ResetPasswordialog() {
    const [resetToken, setResetToken] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [alertContent, setAlertContent] = useState('')
    const [open, setOpen] = React.useState(false);
    const [resetSuccess, setResetSuccess] = React.useState(false);
    const [resetTokenFieldEmpty, setResetTokenFieldEmpty] = useState<boolean>(false)
    const [passwordFieldEmpty, setPasswordFieldEmpty] = useState<boolean>(false)
    const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] = useState<boolean>(false)
    const { submit: resetPasswordSubmit } = useApiRequest(UserAPI.resetPassword);

    const handleClose = () => {
      setOpen(false);

      if (resetSuccess)
        store.dispatch(setMainScreenStateState(1));  
    };

    const handleBack = () => {
        store.dispatch(setMainScreenStateState(0));
    }

    const handleReset = async () => {
    let hasError = 0

    if (resetToken === "")
    {
        setResetTokenFieldEmpty(true)
        hasError = 1
    }
    else 
        setResetTokenFieldEmpty(false)

      if (password === "")
    {
        setPasswordFieldEmpty(true)
        hasError = 1
    }
    else setPasswordFieldEmpty(false)

      if (confirmPassword === "")
      {
        setConfirmPasswordFieldEmpty(true)
            hasError = 1
        }
        else setConfirmPasswordFieldEmpty(false)

      if (hasError === 0 && password !== confirmPassword)
      {
        setAlertContent("Password does not match with Confirm Password");
        setOpen(true);
        setResetSuccess(false);

        return
      }

      if (hasError === 1)
        return

      const resp = await resetPasswordSubmit({
        resetToken: resetToken,
        password: password
      });

      console.log(resp);

      if (resp.data.code)
      {
        setAlertContent(resp.data.message);
        setOpen(true);
        setResetSuccess(false);
      }
      else
      {
        console.log("resp.data", resp.data);

        setAlertContent("Password is reset successfully. Please use the new password to login.");
        setOpen(true);
        setResetSuccess(true);
      }
    }

    return (
        <Wrapper>        
          <Title>Reset Password</Title>
          <Box sx={{ m: 3 }} />
            <TextField
                autoFocus
                fullWidth
                label="Reset Token"
                variant="outlined"
                color="secondary"
                error={resetTokenFieldEmpty}
                helperText={resetTokenFieldEmpty && 'Reset Token is required'}
                onInput={(e) => {
                    setResetToken((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                autoFocus
                fullWidth
                label="Password"
                variant="outlined"
                color="secondary"
                type="password"
                error={passwordFieldEmpty}
                helperText={passwordFieldEmpty && 'Password is required'}
                onInput={(e) => {
                    setPassword((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                autoFocus
                fullWidth
                label="Confirm Password"
                variant="outlined"
                color="secondary"
                type="password"
                error={confirmPasswordFieldEmpty}
                helperText={confirmPasswordFieldEmpty && 'Confirm Password is required'}
                onInput={(e) => {
                    setConfirmPassword((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <Bottom>
                <Button variant="contained" color="secondary" size="large" onClick={() => handleReset()}>
                Confirm
                </Button>
                <Box sx={{ mr: 2 }} />
                <Button variant="contained" color="secondary" size="large" onClick={() => handleBack()}>
                Back
                </Button>
            </Bottom>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {alertContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          
        </Wrapper>
    )
}