import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import Adam from '../assets/Adam_login.png'
import Ash from '../assets/Ash_login.png'
import Lucy from '../assets/Lucy_login.png'
import Nancy from '../assets/Nancy_login.png'
import dots from '../assets/dots.png'

import avatarReel from '../assets/characters-reel.png'
import { useAppSelector, useAppDispatch } from '../hooks'
import { setLoggedIn } from '../stores/UserStore'
import { getAvatarString, getColorByString } from '../util'

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

import Coinbase from "../services/Coinbase"

import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers"

//import { InjectedConnector } from "@web3-react/walletlink-connector";
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
//import { WalletConnectConnector } from "@web3-react/walletlink-connector";

import { useApiRequest } from '../utils/api';

import ClinicAPI from '../services/api/ClinicAPI';
import { CLINIC_ID } from '../utils/constant'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import store from '../stores'
import { setNftVerifyState, setMobileState } from '../stores/UserStore'

import { phaserEvents, Event } from '../events/EventCenter'

SwiperCore.use([Navigation])


const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
 });





const Wrapper = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 335px;
  height: 340px;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  border: 4px solid #000000;
  box-shadow: 0px 0px 5px #0000006f;
  display:flex;
  flex-direction:column;
  z-index:999;
`

const Title = styled.p`
  margin: 5px;
  font-size: 24px;
  color: black;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const ConnectionStatus = styled.div`
  font-size: 15px;
  color: white;
`

const RoomName = styled.div`
  max-width: 500px;
  max-height: 120px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  justify-content: center;

  h3 {
    font-size: 24px;
    color: #eee;
  }
`

const ButtonBook = styled.button`
  font-size: 20px;
  font-weight:500;
  background: linear-gradient(90deg, rgb(66, 233, 192) 2.84%, rgb(41, 174, 232) 100%);
  gap: 9px;
  border-radius: 50px;
  width: 270px;
  height: 47px;
  color: white;
  margin-bottom: 15px;
  border:0px;
`

const Dots = styled.img`
  padding: 0px;
  gap: 18px;

  width: 222px;
  height: 6px;
  margin-top:10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
`

const AvatarImg = styled.img`
  padding: 0px;
  gap: 18px;
  margin: auto;
  width: 222px;
  margin-left: auto;
  margin-right: auto;
`

const RoomDescription = styled.div`
  max-width: 500px;
  max-height: 150px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  font-size: 16px;
  color: #c2c2c2;
  display: flex;
  justify-content: center;
`

const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`

const TopClose = styled.div`
  width: 100%;
  display: flex;
`

const Close = styled.button`
  margin-left: auto;
  border: 0px;
  font-size :18px;
  font-weight: 700;
  background-color: transparent;
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }
`

const Right = styled.div`
  width: 300px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Warning = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const avatars = [
  { name: 'adam', img: Adam },
  { name: 'ash', img: Ash },
  { name: 'lucy', img: Lucy },
  { name: 'nancy', img: Nancy },
]

// shuffle the avatars array
for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1))
  ;[avatars[i], avatars[j]] = [avatars[j], avatars[i]]
}

export default function VendingBookDialog() {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('')
  const [avatarIndex, setAvatarIndex] = useState<number>(0)
  const [nameFieldEmpty, setNameFieldEmpty] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const videoConnected = useAppSelector((state) => state.user?.videoConnected)
  const roomJoined = useAppSelector((state) => state.room.roomJoined)
  const roomName = useAppSelector((state) => state.room.roomName)
  const roomDescription = useAppSelector((state) => state.room.roomDescription)
  const game = phaserGame.scene.keys.game as Game
  const [alertContent, setAlertContent] = useState('')
  const [open, setOpen] = React.useState(false);

  const context = useWeb3React<Web3Provider>()

  const { active, activate, deactivate, library, chainId, account, } = context;

  const handleBook = () => {
    //setOpen(false);
    window.open('https://chinalife.mediconcen.com/', '_blank')
    store.dispatch(setMobileState(0));
  };

  const handleClose = () => {
    //setOpen(false);
    store.dispatch(setMobileState(0));
  };

  const CoinbaseWallet = new WalletLinkConnector({

    url: `https://mainnet.infura.io/v3/RmA884nyVPfGUV1Y`,
   
    appName: "Web3-react Demo",
   
    supportedChainIds: [1, 3, 4, 5, 42],
   
   });

  const { submit: verifyNft } = useApiRequest(ClinicAPI.verifyNft);

  const handleSubmit = () => {
    //const cb = new Coinbase();

    //console.log("URL:", cb.getAuthorizationURL());
    //window.location.replace(cb.getAuthorizationURL());
    //return;
  }

  return (
    <Wrapper>
      <TopClose>
        <Close onClick={handleClose}>X</Close>
      </TopClose>
      <Title>{t('popup.booking.title')}</Title>
      <Dots src={dots}>

      </Dots>
      <Bottom>
          <ButtonBook onClick={handleBook} >
            {t('popup.booking.action')}
          </ButtonBook>
      </Bottom>
      <Bottom>
        <ButtonBook onClick={handleClose}>
            {t('popup.booking.cancel')}
          </ButtonBook>
      </Bottom>
      <AvatarImg src={avatarReel}></AvatarImg>
    </Wrapper>
  )
}
