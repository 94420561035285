import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import { spacing } from '@mui/system'
import store from '../stores'
import { setMainScreenStateState, setUserName, setPlayerName } from '../stores/UserStore'
import { validateEmail } from '../utils/common';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useApiRequest } from '../utils/api';
import { CLINIC_ID } from '../utils/constant'
import UserAPI from '../services/api/UserAPI';
import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'
import { useTranslation } from "react-i18next";

const Wrapper = styled.form`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: #222639;
border-radius: 16px;
padding: 36px 60px;
box-shadow: 0px 0px 5px #0000006f;
`

const Title = styled.p`
margin: 5px;
font-size: 20px;
color: #c2c2c2;
text-align: center;
`


const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }
`

const Right = styled.div`
  width: 300px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`


export default function RegistrationDialog() {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('')
    const [displayName, setDisplayName] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [displayNameError, setDisplayNameError] = useState<string>('')
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [alertContent, setAlertContent] = useState('')
    const [open, setOpen] = React.useState(false);
    const { submit: registerSubmit } = useApiRequest(UserAPI.register);

    const handleClose = () => {
      setOpen(false);
    };

    const handleRegister = async () => {
        var hasError = 0;

        if (email === '')
        {
          setEmailError('Please input Email');
          hasError = 1;
        }

        if (password === '')
        {
          setPasswordError('Please input Password');
          hasError = 1;
        }

        if (displayName === '')
        {
          setDisplayNameError('Please input Full Name');
          hasError = 1;
        }

        if (hasError === 1)
          return;

        const resp = await registerSubmit({
          userName: email, password: password, displayName: displayName, clinicId: CLINIC_ID
        });

        console.log(resp);

        if (resp.data.code)
        {
          setAlertContent(resp.data.message);
          setOpen(true);
        }
        else
        {
          console.log("resp.data", resp.data);

          const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
        bootstrap.network
          .joinOrCreatePublic()
          .then(() => bootstrap.launchGame(false, "Patient"))
          .catch((error) => console.error(error));

          store.dispatch(setMainScreenStateState(0));
          store.dispatch(setUserName(resp.data.userName));
          store.dispatch(setPlayerName(resp.data.displayName));

          //store.dispatch(setMainScreenStateState(0));
        }
    }

    const handleBack = () => {
        store.dispatch(setMainScreenStateState(0));
    }

    const validateEmailFormat = (val = ''): boolean => {
      if (val.length === 0) {
        setEmailError('');
        return false;
      }
  
      const isVerified = validateEmail(val);
  
      if (!isVerified) {
        setEmailError("Email format is invalid");
        return false;
      }
  
      setEmailError('');
      return true;
    };

    const validateDisplayName = (val = ''): boolean => {
      setDisplayNameError('');
      return true;
    } 

    const validatePassword = (val = ''): boolean => {
      if (val.length === 0) {
        setPasswordError('');
        return false;
      }

      if (password.length <= 5) {
        setPasswordError("Password too short");//
        return false;
      }
  
      setPasswordError('');
      return true;
    };

    const validateConfirmPassword = (val = ''): boolean => {
      if (val.length === 0) {
        setConfirmPasswordError('');
        return false;
      }

      if (confirmPassword !== password) {
        setConfirmPasswordError("Password not match");//
        return false;
      }
  
      setConfirmPasswordError('');
      return true;
    };


    useEffect(() => {
      validateEmailFormat(email);
    }, [email]);

    useEffect(() => {
      validateDisplayName(displayName);
    }, [displayName]);

    useEffect(() => {
      validatePassword(password);
    }, [password]);

    useEffect(() => {
      validateConfirmPassword(confirmPassword);
    }, [confirmPassword]);
  

    return (
        <Wrapper>        
          <Title>{ t('register.title')}</Title>
          <Box sx={{ m: 3 }} />
            <TextField
                autoFocus
                fullWidth
                label={ t('register.username')}
                variant="outlined"
                color="secondary"
                helperText={emailError}
                FormHelperTextProps={{ style: { color: 'red' }}}
                onInput={(e) => {
                    setEmail((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                fullWidth
                label={ t('register.fullname')}
                variant="outlined"
                color="secondary"
                helperText={displayNameError}
                FormHelperTextProps={{ style: { color: 'red' }}}
                onInput={(e) => {
                    setDisplayName((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                fullWidth
                label={ t('register.password')}
                variant="outlined"
                color="secondary"
                type="password"
                helperText={passwordError}
                FormHelperTextProps={{ style: { color: 'red' }}}
                onInput={(e) => {
                    setPassword((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <TextField
                fullWidth
                label={ t('register.conpassword')}
                variant="outlined"
                color="secondary"
                type="password"
                helperText={confirmPasswordError}
                FormHelperTextProps={{ style: { color: 'red' }}}
                //error={confirmPasswordFieldEmpty}
                //helperText={confirmPasswordFieldEmpty && 'Confirm Password is required'}
                onInput={(e) => {
                    setConfirmPassword((e.target as HTMLInputElement).value)
                }}
            />
            <Box sx={{ m: 2 }} />
            <Bottom>
                <Button variant="contained" color="secondary" size="large" onClick={() => handleRegister()}>
                { t('register.submit')}
                </Button>
                <Box sx={{ mr: 2 }} />
                <Button variant="contained" color="secondary" size="large" onClick={() => handleBack()}>
                { t('register.back')}
                </Button>
            </Bottom>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {alertContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                { t('register.ok')}
                </Button>
              </DialogActions>
            </Dialog>
          
        </Wrapper>
    )
}