import { ItemType } from '../../../types/Items'
import Item from './Item'
import store from '../stores'
import i18n from "../i18n"
export default class MainDoor extends Item {
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.itemType = ItemType.MAINDOOR
  }

  onOverlapDialog() {
    // If doctor only

    // If closed
    const userType = store.getState().user.userType

    console.log("userType: ", store.getState().user)

    if (userType === 'Doctor')
    
      this.setDialogBox(i18n.t('game.door.open'))
    else 
      this.setDialogBox(i18n.t('game.door.close'))
  }
}
