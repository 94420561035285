import Phaser from 'phaser'
import MyPlayer from './MyPlayer'
import { PlayerBehavior } from '../../../types/PlayerBehavior'
import { GlobalStatus } from '../../../types/GlobalStatus'
import Item from '../items/Item'

export default class PlayerSelector extends Phaser.GameObjects.Zone {
  selectedItem?: Item

  constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number) {
    super(scene, x, y, width, height)

    scene.physics.add.existing(this)
  }

  update(player: MyPlayer, cursors: Phaser.Types.Input.Keyboard.CursorKeys, pointer?: Phaser.Input.Pointer) {
    if (pointer) {
      if (pointer.isDown) {
        const { x, y } = player
        let realPosX = pointer.worldX;
        let realposY = pointer.worldY;
        if (realPosX - this.body.position.x >= 50 ) {
          this.setPosition(x + 32, y)
  
        } 
    
        if (realPosX - this.body.position.x <= -50 ) {
  
          this.setPosition(x - 32, y)
        }
    
        if (realposY - this.body.position.y <= -50 ) {
          this.setPosition(x, y - 32)
  
        } 
    
        if (realposY - this.body.position.y >= 50 ) {
          this.setPosition(x, y + 32)
        } 
      }
    }
    
    if (!cursors) {
      return
    }

    // no need to update player selection while sitting
    if (player.playerBehavior === PlayerBehavior.SITTING) {
      return
    }
    //**--Set the size of collision box here--**
    // update player selection box position so that it's always in front of the player
    const { x, y } = player
    if (cursors.left?.isDown) {
      this.setPosition(x - 32, y)
    } else if (cursors.right?.isDown) {
      this.setPosition(x + 32, y)
    } else if (cursors.up?.isDown) {
      this.setPosition(x, y - 32)
    } else if (cursors.down?.isDown) {
      this.setPosition(x, y + 32)
    }

    // while currently selecting an item,
    // if the selector and selection item stop overlapping, clear the dialog box and selected item
    if (this.selectedItem) {
      if (!this.scene.physics.overlap(this, this.selectedItem)) {
        this.selectedItem.clearDialogBox()
        this.selectedItem = undefined
      }
    }
  }

  
}
