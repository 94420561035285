import type { Auth } from '../types/auth';
//import { MemberProfile } from '@src/types/member';
//import { MemberGift } from '@src/types/memberGift';
//import { Locale } from '@src/types/translations';
import { HK_PHONE_LENGTH } from './constant';

type PersistableDataType = {
  //member: MemberProfile | null;
  //memberGift: MemberGift | null;
  auth: Auth | null;
};

type PersistableDataKey = keyof PersistableDataType;

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getPersistedData = (key: PersistableDataKey) => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};

export const persistData = <T extends PersistableDataKey>(key: T, value: PersistableDataType[T]) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const languageType = {
  EN: 'en',
  TC: 'tc',
} as const;
export type LanguageType = typeof languageType[keyof typeof languageType];

export const validateAtLeastOneLowerChar = (password: string) => {
  const re = /([a-z])+/g;
  return re.test(String(password));
};

export const validateAtLeastOneUpperChar = (password: string) => {
  const re = /([A-Z])+/g;
  return re.test(String(password));
};

export const validateAtLeastOneNumber = (password: string) => {
  const re = /\d+/g;
  return re.test(String(password));
};

export const validateAtLeastOneSpecialChar = (password: string) => {
  const re = /([!@#$%^&*()_+|~\-=`{}\[\]:";'<>\?,\.\/])+/;
  return re.test(String(password));
};

export const concatName = (firstName: string, lastName: string) => {
  return firstName + ' ' + lastName;
};

export const convertDateDiffToHhmmss = (value: number) => {
  if (value <= 0) {
    return '00:00:00';
  }

  const MS_PER_SECOND = 1000;
  const MS_PER_MIN = 60 * MS_PER_SECOND;
  const MS_PER_HOUR = 60 * MS_PER_MIN;
  const MS_PER_DAY = 24 * MS_PER_HOUR;

  const s = (value % MS_PER_MIN) / MS_PER_SECOND;
  const m = (value % MS_PER_HOUR) / MS_PER_MIN;
  const h = (value % MS_PER_DAY) / MS_PER_HOUR;

  return [h, m, s].map((value) => `${Math.floor(value)}`.padStart(2, '00')).join(':');
};

export const validateHkPhone = (phone: string) => phone.length === HK_PHONE_LENGTH;

export const validateEnglishOnly = (text: string) => /^[a-zA-Z| ]+$/.test(text);

export const verifyPassword = (password: string) =>
  validateAtLeastOneLowerChar(password) &&
  validateAtLeastOneUpperChar(password) &&
  validateAtLeastOneNumber(password) &&
  validateAtLeastOneSpecialChar(password);

export interface ResponseSuccessType<T> {
    status: number;
    data: T | null;
  }
