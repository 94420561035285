import RequestService, { basePath } from '../RequestService';

export type CheckAssetStatusRequestBodyType = {
    clinicId?: string;
    assetType: string;
};

const checkAssetStatus = (body: Partial<CheckAssetStatusRequestBodyType>) => {
  const url = `${basePath}/clinic-asset/check-status`;
  return RequestService.post(url, body);
}

const ClinicAssetAPI = {
    checkAssetStatus
  };
  
export default ClinicAssetAPI;