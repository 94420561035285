import Phaser, { Scene } from 'phaser'

// import { debugDraw } from '../utils/debug'
import { createCharacterAnims } from '../anims/CharacterAnims'

import Item from '../items/Item'
import Chair from '../items/Chair'
import Computer from '../items/Computer'
import Whiteboard from '../items/Whiteboard'
import VendingMachine from '../items/VendingMachine'
import ExitDoor from '../items/ExitDoor'
import ShopDoor from '../items/ShopDoor'
import MainDoor from '../items/MainDoor'
import DoctorChair from '../items/DoctorChair'
import '../characters/MyPlayer'
import '../characters/OtherPlayer'
import MyPlayer from '../characters/MyPlayer'
import OtherPlayer from '../characters/OtherPlayer'
import PlayerSelector from '../characters/PlayerSelector'
import Network from '../services/Network'
import { IPlayer } from '../../../types/IOfficeState'
import { PlayerBehavior } from '../../../types/PlayerBehavior'
import { GlobalStatus } from '../../../types/GlobalStatus'
import { ItemType } from '../../../types/Items'
import ClinicAPI from '../services/api/ClinicAPI';
import { phaserEvents, Event } from '../events/EventCenter'

//import { KickoutPosX, KickoutPosY } from "../../../server/types/commons";

import store from '../stores'
import { setFocused, setShowChat } from '../stores/ChatStore'
import WebRTC from '../web/WebRTC'

export default class Game extends Phaser.Scene {
  network!: Network
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
  private keyE!: Phaser.Input.Keyboard.Key
  private keyV!: Phaser.Input.Keyboard.Key
  private keyB!: Phaser.Input.Keyboard.Key
  private keyK!: Phaser.Input.Keyboard.Key
  private keyO!: Phaser.Input.Keyboard.Key  
  public map!: Phaser.Tilemaps.Tilemap
  myPlayer!: MyPlayer
  private playerSelector!: Phaser.GameObjects.Zone
  private otherPlayers!: Phaser.Physics.Arcade.Group
  private otherPlayerMap = new Map<string, OtherPlayer>()
  computerMap = new Map<string, Computer>()
  private whiteboardMap = new Map<string, Whiteboard>()
  private doctorJoined: boolean = false

  private mainDoor: Phaser.Physics.Arcade.StaticGroup
  private clinicRoomDoor: Phaser.Physics.Arcade.StaticGroup

  public text;

  //public playerType: string = "Patient"

  constructor() {
    super('game')
    console.log("CREATING GAME!!!!!!!!!!")
  }


  closeDoor () {
    const resp = ClinicAPI.closeClinic();
    console.log(resp)
    return
  }

  registerKeys() {
    this.cursors = this.input.keyboard.createCursorKeys()
    // maybe we can have a dedicated method for adding keys if more keys are needed in the future
    this.keyE = this.input.keyboard.addKey('E')
    this.keyV = this.input.keyboard.addKey('V')
    //Add a key B to represent booking here
    this.keyB = this.input.keyboard.addKey('B')
    //Add a key O to represent open door here
    this.keyO = this.input.keyboard.addKey('O')
    this.keyK = this.input.keyboard.addKey('K')
    this.input.keyboard.disableGlobalCapture()
    this.input.keyboard.on('keydown-ENTER', (event) => {
      store.dispatch(setShowChat(true))
      store.dispatch(setFocused(true))
    })
    this.input.keyboard.on('keydown-ESC', (event) => {
      store.dispatch(setShowChat(false))
    })
  }

  disableKeys() {
    this.input.keyboard.enabled = false
  }

  enableKeys() {
    this.input.keyboard.enabled = true
  }

  create(data: { network: Network, hideMainDoor: boolean, playerType: string }) {
    if (!data.network) {
      throw new Error('server instance missing')
    } else {
      this.network = data.network
    }

    this.input.addPointer(1);

    createCharacterAnims(this.anims)

    this.map = this.make.tilemap({ key: 'tilemap' })
    const FloorAndGround = this.map.addTilesetImage('FloorAndGround', 'tiles_wall')

    const groundLayer = this.map.createLayer('Ground', FloorAndGround)
    groundLayer.setCollisionByProperty({ collides: true })

    // debugDraw(groundLayer, this)

    this.myPlayer = this.add.myPlayer(705, 500, 'adam', this.network.mySessionId, data.playerType, false)
    //this.myPlayer.playerType = data.playerType
    //this.myPlayer.isInsideClinicRoom = false


    this.network.setMyPlayer(this.myPlayer, data.playerType)

    console.log("CREATE game this.myPlayer.playerType", this.myPlayer.playerType)
    this.network.updatePlayer(this.myPlayer.x, this.myPlayer.y, this.myPlayer.anims.currentAnim.key, this.myPlayer.playerType, this.myPlayer.isInsideClinicRoom)

    this.playerSelector = new PlayerSelector(this, 0, 0, 16, 16)

    // import chair objects from Tiled map to Phaser
    const chairs = this.physics.add.staticGroup({ classType: Chair })
    const chairLayer = this.map.getObjectLayer('Chair')
    chairLayer.objects.forEach((chairObj) => {
      const item = this.addObjectFromTiled(chairs, chairObj, 'chairs', 'chair') as Chair
      // custom properties[0] is the object direction specified in Tiled
      item.itemDirection = chairObj.properties[0].value
    })

    // import doctor chair objects from Tiled map to Phaser
    const doctorChairs = this.physics.add.staticGroup({ classType: DoctorChair })
    const doctorChairLayer = this.map.getObjectLayer('DoctorChair')
    doctorChairLayer.objects.forEach((chairObj) => {
      const item = this.addObjectFromTiled(doctorChairs, chairObj, 'doctorchair', 'chair') as DoctorChair
      // custom properties[0] is the object direction specified in Tiled
      item.itemDirection = chairObj.properties[0].value
    })

    // import computers objects from Tiled map to Phaser
    const computers = this.physics.add.staticGroup({ classType: Computer })
    const computerLayer = this.map.getObjectLayer('Computer')
    computerLayer.objects.forEach((obj, i) => {
      const item = this.addObjectFromTiled(computers, obj, 'computers', 'computer') as Computer
      item.setDepth(item.y + item.height * 0.27)
      const id = `${i}`
      item.id = id
      this.computerMap.set(id, item)
    })

    // import whiteboards objects from Tiled map to Phaser
    // const whiteboards = this.physics.add.staticGroup({ classType: Whiteboard })
    // const whiteboardLayer = this.map.getObjectLayer('Whiteboard')
    // whiteboardLayer.objects.forEach((obj, i) => {
    //   const item = this.addObjectFromTiled(
    //     whiteboards,
    //     obj,
    //     'whiteboards',
    //     'whiteboard'
    //   ) as Whiteboard
    //   const id = `${i}`
    //   item.id = id
    //   this.whiteboardMap.set(id, item)
    // })

    // import vending machine objects from Tiled map to Phaser
    const vendingMachines = this.physics.add.staticGroup({ classType: VendingMachine })
    const vendingMachineLayer = this.map.getObjectLayer('VendingMachine')
    vendingMachineLayer.objects.forEach((obj, i) => {
      this.addObjectFromTiled(vendingMachines, obj, 'nursebookings', 'vendingmachine')
    })

    //import exit
    const exitDoors = this.physics.add.staticGroup({ classType: ExitDoor })
    const exitDoorLayer = this.map.getObjectLayer('exit')
    exitDoorLayer.objects.forEach((obj, i) => {
      this.addObjectFromTiled(exitDoors, obj, 'tiles_wall', 'FloorAndGround')
    })


    // ** import door objects from Tiled map to Phaser **



    const shopDoors = this.physics.add.staticGroup({ classType: ShopDoor })
    const shopDoorLayer = this.map.getObjectLayer('ShopDoor')
    shopDoorLayer.objects.forEach((obj, i) => {

      //this.shopDoor = obj
      this.addObjectFromTiled(shopDoors, obj, 'shopdoor', 'DoorRPGSmall')
    })

    this.clinicRoomDoor = shopDoors

    const mainDoors = this.physics.add.staticGroup({ classType: MainDoor })

    if (!data.hideMainDoor)
    {
      const mainDoorLayer = this.map.getObjectLayer('MainDoor')
      mainDoorLayer.objects.forEach((obj, i) => {
        this.addObjectFromTiled(mainDoors, obj, 'maindoor', 'DoorRPGSmall')
      })

      console.log("SETTING MAIN DOOR!!!")

      this.mainDoor = mainDoors

      const mainDoorState = store.getState().room.mainDoor;

      if (mainDoorState == false) {
        this.removeMainDoor()
      }
    }

    // ** import other objects from Tiled map to Phaser. **
    // objectLayerName: string, (Defined in tiled map.json)
    // key: string, (Defined in bootstrap.ts)
    // tilesetName: string, (Name defined in tiled map.json) it in lowercase of value name
    // collidable: boolean  (False: no collision)

    this.addGroupFromTiled('Wall', 'tiles_wall', 'FloorAndGround', false)
    this.addGroupFromTiled('BottomWall', 'tiles_wall', 'FloorAndGround', true)
    this.addGroupFromTiled('Objects', 'office', 'Modern_Office_Black_Shadow', false)
    this.addGroupFromTiled('ObjectsOnCollide', 'office', 'Modern_Office_Black_Shadow', true)
    this.addGroupFromTiled('GenericObjects', 'generic', 'Generic', false)
    this.addGroupFromTiled('clLogo', 'cl', 'cl', false)
    this.addGroupFromTiled('SideDoor', 'sidedoor', 'DoorRPGSmall', true)
    this.addGroupFromTiled('GenericObjectsOnCollide', 'generic', 'Generic', true)
    this.addGroupFromTiled('Basement', 'basement', 'Basement', true)

    //Add cupboard to the game scene
    this.addGroupFromTiled('Cupboard', 'cupboard', 'DoorRPGSmall', true)

    this.otherPlayers = this.physics.add.group({ classType: OtherPlayer })

    this.cameras.main.zoom = 1.5
    this.cameras.main.startFollow(this.myPlayer, true)

    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], groundLayer)
    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], vendingMachines)
    //add collider to shopdoor
    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], shopDoors)

    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], exitDoors)

    if (!data.hideMainDoor)
      this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], mainDoors)

    this.physics.add.overlap(
      this.playerSelector,
      [chairs, doctorChairs , computers, vendingMachines, shopDoors, mainDoors, exitDoors],
      this.handleItemSelectorOverlap,
      undefined,
      this
    )

    this.physics.add.overlap(
      this.myPlayer,
      this.otherPlayers,
      this.handlePlayersOverlap,
      undefined,
      this
    )

    // register network event listeners
    this.network.onPlayerJoined(this.handlePlayerJoined, this)
    this.network.onPlayerLeft(this.handlePlayerLeft, this)
    this.network.onMyPlayerReady(this.handleMyPlayerReady, this)
    this.network.onMyPlayerVideoConnected(this.handleMyVideoConnected, this)
    this.network.onPlayerUpdated(this.handlePlayerUpdated, this)
    this.network.onItemUserAdded(this.handleItemUserAdded, this)
    this.network.onItemUserRemoved(this.handleItemUserRemoved, this)
    this.network.onChatMessageAdded(this.handleChatMessageAdded, this)

    this.network.onPlayerRoomStatusUpdated(this.handlePlayerRoomStatusUpdated, this)

    this.network.onDoctorJoined(this.handleDoctorJoined, this)

    this.network.onMainDoorRemoved(this.handleMainDoorRemoved, this)

    this.network.onMainDoorAdded(this.handleMainDoorAdded, this)

    console.log("FINISHED CREATING GAME!!!!!!!!!!")
  }

  public makeCall() {
    var canCall = false
    console.log("working")
    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
      console.log("makeCall other key", key)
      console.log("makeCall other playerType", value.playerType)
      console.log("makeCall other globalStatus", value.globalStatus)
      console.log("makeCall other isInsideClinicRoom", value.isInsideClinicRoom)

      if (value.playerType === 'Patient' && value.isInsideClinicRoom)
      {
        canCall = true
        value.makeCall(this.myPlayer, this.network?.webRTC)
        return
      }
    });

    return canCall
  }

  public closeCall() {
    var canCall = false
    let counter = 1;
    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
      console.log("COUNTER"+counter);
      console.log("makeCall other key", key)
      console.log("makeCall other playerType", value.playerType)
      console.log("makeCall other globalStatus", value.globalStatus)
      console.log("makeCall other isInsideClinicRoom", value.isInsideClinicRoom)

      if (value.playerType === 'Patient' && value.isInsideClinicRoom)
      {
        value.closeCall(this.myPlayer, this.network?.webRTC)
        return
      }
      counter++;
    });
    return canCall
  }

  /*public setMyPlayerType(_playerType: string) {
    console.log("Game.ts setMyPlayerType", _playerType)
    this.playerType = _playerType
  }*/

  private handleItemSelectorOverlap(playerSelector, selectionItem) {
    const currentItem = playerSelector.selectedItem as Item
    // currentItem is undefined if nothing was perviously selected
    if (currentItem) {
      // if the selection has not changed, do nothing
      if (currentItem === selectionItem || currentItem.depth >= selectionItem.depth) {
        return
      }
      // if selection changes, clear pervious dialog
      if (this.myPlayer.playerBehavior !== PlayerBehavior.SITTING) currentItem.clearDialogBox()
    }
    // set selected item and set up new dialog
    playerSelector.selectedItem = selectionItem
    if (selectionItem.itemType == 3 ){ //check if selected item is vending machine and player is not a doctor
      selectionItem.onOverlapDialog(this.myPlayer.playerType) // using custom function if it vending machine type
    } else {
      selectionItem.onOverlapDialog()
    }


  }

  private removeObject(
    group: Phaser.Physics.Arcade.StaticGroup,
    object: Phaser.Types.Tilemaps.TiledObject,
    key: string,
    tilesetName: string
  ) {
    const actualX = object.x! + object.width! * 0.5
    const actualY = object.y! - object.height! * 0.5

    this.map.getTileset(tilesetName).setTileSize(0);//.updateTileData();
    //this.map.getTileset(tilesetName)
    group
      .get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid)
      .setDepth(0)
  }

  private addObjectFromTiled(
    group: Phaser.Physics.Arcade.StaticGroup,
    object: Phaser.Types.Tilemaps.TiledObject,
    key: string,
    tilesetName: string
  ) {
    const actualX = object.x! + object.width! * 0.5
    const actualY = object.y! - object.height! * 0.5
    const obj = group
      .get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid)
      .setDepth(actualY)

    //console.log("addObjectFromTiled", obj)
    return obj
  }


  private addGroupFromTiled(
    objectLayerName: string,
    key: string,
    tilesetName: string,
    collidable: boolean
  ) {
    const group = this.physics.add.staticGroup()
    const objectLayer = this.map.getObjectLayer(objectLayerName)
    objectLayer.objects.forEach((object) => {
      const actualX = object.x! + object.width! * 0.5
      const actualY = object.y! - object.height! * 0.5
      group
        .get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid)
        .setDepth(actualY)
    })
    if (this.myPlayer && collidable)
      this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], group)
  }

  // function to add new player to the otherPlayer group
  public handlePlayerJoined(newPlayer: IPlayer, id: string) {
    try {
      setTimeout(() => {
      const otherPlayer = this.add.otherPlayer(newPlayer.x, newPlayer.y, newPlayer.anim, id, newPlayer.name, newPlayer.playerType, newPlayer.isInsideClinicRoom)
      this.otherPlayers.add(otherPlayer)
      this.otherPlayerMap.set(id, otherPlayer)
      }, 200);
    } catch (err) {
      console.log("player joined object",err)
    }
    
    //const otherPlayers = new OtherPlayer(this,newPlayer.x,newPlayer.y,texture[0],id,newPlayer.name,newPlayer.playerType,newPlayer.isInsideClinicRoom)


    
  }

  private handlePlayerRoomStatusUpdated(isInsideClinicRoom: boolean, x: number, y:number, id: string) {
    console.log("GAME handlePlayerRoomStatusUpdated isInsideClinicRoom", isInsideClinicRoom)
    console.log("GAME handlePlayerRoomStatusUpdated x", x)
    console.log("GAME handlePlayerRoomStatusUpdated y", y)
    console.log("GAME handlePlayerRoomStatusUpdated id", id)

    if (this.otherPlayerMap.has(id)) {
      const otherPlayer = this.otherPlayerMap.get(id)
      if (!otherPlayer) return

      otherPlayer.globalStatus = (isInsideClinicRoom ? GlobalStatus.INDRROOM : GlobalStatus.OUTSIDE)
      otherPlayer.isInsideClinicRoom = isInsideClinicRoom
      //otherPlayer.x = x
      //otherPlayer.y = y
      otherPlayer?.updateOtherPlayer("x", x)
      otherPlayer?.updateOtherPlayer("y", y)

      this.otherPlayerMap.set(id, otherPlayer)
    }
  }

  private handleDoctorJoined(joined: boolean) {
    console.log("GAME handleDoctorJoined", joined)

    //this.doctorJoined = joined

    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
      if (value.playerType === 'Doctor')
        value.isInsideClinicRoom = joined
        //return false
    });

    this.removeMainDoor()
  }

  // function to remove the player who left from the otherPlayer group
  private handlePlayerLeft(id: string) {
    console.log("handle left")
    if (this.otherPlayerMap.has(id)) {
      const otherPlayer = this.otherPlayerMap.get(id)
      if (!otherPlayer) return
      this.otherPlayers.remove(otherPlayer, true, true)
      this.otherPlayerMap.delete(id)
    }
  }

  private handleMyPlayerLeft(id: string) {
    console.log("handle left")
    const otherPlayer = this.otherPlayerMap.get(id)
    if (!otherPlayer) return
    this.otherPlayers.remove(otherPlayer, true, true)
    this.otherPlayerMap.delete(id)
  }

  private handleMyPlayerReady() {
    this.myPlayer.readyToConnect = true
  }

  private handleMyVideoConnected() {
    this.myPlayer.videoConnected = true
  }

  // function to update target position upon receiving player updates
  private handlePlayerUpdated(field: string, value: number | string, id: string) {

    console.log("GAME handlePlayerUpdated", id)
    console.log("GAME handlePlayerUpdated field", field)
    console.log("GAME handlePlayerUpdated value", value)

    const otherPlayer = this.otherPlayerMap.get(id)
    otherPlayer?.updateOtherPlayer(field, value)
  }

  private handlePlayersOverlap(myPlayer, otherPlayer) {
    //otherPlayer.makeCall(myPlayer, this.network?.webRTC)
  }

  private handleItemUserAdded(playerId: string, itemId: string, itemType: ItemType) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId)
      computer?.addCurrentUser(playerId)
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId)
      whiteboard?.addCurrentUser(playerId)
    }
  }

  private handleItemUserRemoved(playerId: string, itemId: string, itemType: ItemType) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId)
      computer?.removeCurrentUser(playerId)
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId)
      whiteboard?.removeCurrentUser(playerId)
    }
  }

  private handleChatMessageAdded(playerId: string, content: string) {
    const otherPlayer = this.otherPlayerMap.get(playerId)
    otherPlayer?.updateDialogBubble(content)
  }

  private handleMainDoorRemoved() {
    this.removeMainDoor()
  }

  private handleMainDoorAdded() {
    this.spawnMainDoor()
  }




  update(t: number, dt: number) {
    if (this.myPlayer && this.network && store.getState().chat?.focused == false) {
      this.playerSelector.update(this.myPlayer, this.cursors,  this.input.activePointer)
      //Game scene to pass event to player
      this.myPlayer.update(this.playerSelector, this.cursors, this.keyE, this.keyV, this.keyK, this.keyB, this.keyO, this.network, this.input.activePointer, this.cameras)
    }

    
  }
  

  public resetMap() {
    this.otherPlayers.clear()
    this.otherPlayerMap.clear()
  }

  public async spawnMainDoor() {

    const mainDoors = this.physics.add.staticGroup({ classType: MainDoor })
    const mainDoorLayer = this.map.getObjectLayer('MainDoor')
      mainDoorLayer.objects.forEach((obj, i) => {
        this.addObjectFromTiled(mainDoors, obj, 'maindoor', 'DoorRPGSmall')
      })
    this.mainDoor = mainDoors;
    this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], mainDoors)
    this.physics.add.overlap(
      this.playerSelector,
      [mainDoors],
      this.handleItemSelectorOverlap,
      undefined,
      this
    )
    if (this.myPlayer.x >= 630 && this.myPlayer.x <= 1024 && this.myPlayer.y >= 60 && this.myPlayer.y <= 320) { // basicaly move player inside the clinic 
      this.myPlayer.x = 695//KickoutPosX//1000
      this.myPlayer.y = 320//KickoutPosY//280
      this.myPlayer.globalStatus = GlobalStatus.OUTSIDE
      this.myPlayer.isInsideClinicRoom = false

      this.network.updatePlayer(this.myPlayer.x, this.myPlayer.y, this.myPlayer.anims.currentAnim.key, this.myPlayer.playerType, this.myPlayer.isInsideClinicRoom)
    }
  }

  public removeMainDoor() {
    console.log("removeMainDoor 1");
    if (this.mainDoor) {
      console.log(this.mainDoor)
      this.mainDoor.clear(true)
      // while (this.mainDoor.children.entries.length !== 0) {
      //   this.mainDoor.children.entries.map((entry) => {
      //     entry.destroy();
      //   })
      //   console.log("removeMainDoor 2");
      // }
    }
  }

  public removeClinicRoomDoor() {
    /*if (this.clinicRoomDoor)
    while (this.clinicRoomDoor.children.entries.length !== 0) {
      this.clinicRoomDoor.children.entries[0].destroy();
    }*/
    this.myPlayer.x = 905//KickoutPosX//1000
    this.myPlayer.y = 210//KickoutPosY//280
    this.myPlayer.globalStatus = GlobalStatus.INDRROOM
    this.myPlayer.isInsideClinicRoom = true

    this.network.updatePlayer(this.myPlayer.x, this.myPlayer.y, this.myPlayer.anims.currentAnim.key, this.myPlayer.playerType, this.myPlayer.isInsideClinicRoom)
  }

  public updateSprite(playerId) {
    /*if (this.clinicRoomDoor)
    while (this.clinicRoomDoor.children.entries.length !== 0) {
      this.clinicRoomDoor.children.entries[0].destroy();
    }*/
    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
        phaserEvents.emit(Event.PLAYER_UPDATED, 'anim',value.anims.currentAnim.key , key)
    })

  }

  public checkPatientCanEnterClinicRoom() {
    //if (!this.doctorJoined)
    //    return false

    var canEnter = true
    var hasDoctor = false

    /*for (let p of this.otherPlayerMap) {
      let value: OtherPlayer

      p.
      value = p.
      console.log("checkPatientCanEnterClinicRoom other key", key)
      console.log("checkPatientCanEnterClinicRoom other playerType", value.playerType)
      console.log("checkPatientCanEnterClinicRoom other globalStatus", value.globalStatus)
      console.log("checkPatientCanEnterClinicRoom other joinedClinicRoom", value.joinedClinicRoom)

      if (value.playerType === 'Patient' && value.globalStatus === GlobalStatus.INDRROOM)
        return false

      if (value.playerType === 'Doctor' && !value.isInsideClinicRoom)
      {
        console.log("checkPatientCanEnterClinicRoom BLOCKING")
        return false
      }
    }*/
    //check current player camera on or not
    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
      console.log("checkPatientCanEnterClinicRoom other key", key)
      console.log("checkPatientCanEnterClinicRoom other playerType", value.playerType)
      console.log("checkPatientCanEnterClinicRoom other globalStatus", value.globalStatus)
      console.log("checkPatientCanEnterClinicRoom other isInsideClinicRoom", value.isInsideClinicRoom)

      if (value.playerType === 'Patient' && value.isInsideClinicRoom)////value.globalStatus === GlobalStatus.INDRROOM)
      {
        canEnter = false
        return
      }

      if (value.playerType === 'Doctor')
        hasDoctor = true


      if (value.playerType === 'Doctor' && !value.isInsideClinicRoom)
      {
        console.log("checkPatientCanEnterClinicRoom BLOCKING")
        canEnter = false
        return
      }
    });

    if (!hasDoctor)
      return false

    console.log("checkPatientCanEnterClinicRoom EXIT TRUE")

    return canEnter
  }

  public movePlayersOutofClinicRoom() {
    this.network.movePatientsOutOfClinicRoom()

    this.otherPlayerMap.forEach((value: OtherPlayer, key: string) => {
        //check player inside room or not if not dont move it
        if (value.playerType === 'Patient' && value.globalStatus === GlobalStatus.INDRROOM)// && value.globalStatus === GlobalStatus.INDRROOM)
        {
          value.updateOtherPlayer('x', 855);//KickoutPosX);
          value.updateOtherPlayer('y', 210);//KickoutPosY);
          value.globalStatus = GlobalStatus.OUTSIDE
          value.isInsideClinicRoom = false
        }
    });
  }

  public doorInteract() {
    this.myPlayer.DoorInteraction(this.playerSelector, this.network)
  }
}
