import RequestService, { basePath } from '../RequestService';

export type VerifyNftRequestBodyType = {
    clinicId?: string;
    walletAddress?: string;
  };

const verifyNft = (body: Partial<VerifyNftRequestBodyType>) => {
    const url = `${basePath}/clinic/verify-nft`;
    return RequestService.post(url, body);
}

const closeClinic = () => {
  const url = `${basePath}/clinic/close-doors`;
  return RequestService.get(url);
}

const ClinicAPI = {
    verifyNft,
    closeClinic
};

export default ClinicAPI;