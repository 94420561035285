import RequestService, { basePath } from '../RequestService';
import { ResponseSuccessType } from '../../utils/common';
import { Time } from 'phaser';

export interface BookingRequestBodyType {
    passcode: string;
    clinicId: string;
    bookingDate: Date;
    bookingStartTime: Date;
    bookingEndTime: Date;
};

const getBookingByPasscode = (body: Partial<BookingRequestBodyType>) => {
    const url = `${basePath}/booking/get`;
    return RequestService.post<BookingRequestBodyType, ResponseSuccessType<BookingRequestBodyType>>(url, body);
}

const BookingAPI = {
    getBookingByPasscode
  };

export default BookingAPI;