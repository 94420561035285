import { Client, Room } from 'colyseus.js'
import { IComputer, IOfficeState, IPlayer, IWhiteboard } from '../../../types/IOfficeState'
import { Message } from '../../../types/Messages'
import { IRoomData, RoomType } from '../../../types/Rooms'
import { ItemType } from '../../../types/Items'
import WebRTC from '../web/WebRTC'
import { phaserEvents, Event } from '../events/EventCenter'
import store from '../stores'
import { setSessionId, setPlayerNameMap, removePlayerNameMap, setUserType, setUserName } from '../stores/UserStore'
import {
  setLobbyJoined,
  setJoinedRoomData,
  setAvailableRooms,
  addAvailableRooms,
  removeAvailableRooms,
} from '../stores/RoomStore'
import {
  pushChatMessage,
  pushPlayerJoinedMessage,
  pushPlayerLeftMessage,
} from '../stores/ChatStore'
import { setWhiteboardUrls } from '../stores/WhiteboardStore'
import MyPlayer from '../characters/MyPlayer'
import { GlobalStatus } from '../../../types/GlobalStatus'
//import { KickoutPosX, KickoutPosY } from "../../../server/types/commons";
import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

import ClinicAPI from '../services/api/ClinicAPI';
import { useApiRequest } from '../utils/api';

export default class Network {
  private client: Client
  private room?: Room<IOfficeState>
  private lobby!: Room
  webRTC?: WebRTC
  myPlayer: MyPlayer

  mySessionId!: string

  constructor() {
    const protocol = window.location.protocol.replace('http', 'ws')
    const endpoint =
      process.env.NODE_ENV === 'production'
        ? `wss://sky-office.herokuapp.com`
        : `wss://mccbox-ws.mediconcen.com`
		//: `${protocol}//${window.location.hostname}:2567`
    this.client = new Client(endpoint)
    this.joinLobbyRoom().then(() => {
      store.dispatch(setLobbyJoined(true))
    })

    phaserEvents.on(Event.MY_PLAYER_NAME_CHANGE, this.updatePlayerName, this)
    phaserEvents.on(Event.MY_PLAYER_TEXTURE_CHANGE, this.updatePlayer, this)
    phaserEvents.on(Event.PLAYER_DISCONNECTED, this.playerStreamDisconnect, this)
    phaserEvents.on(Event.UPDATE_PLAYER_ROOM_STATUS, this.updatePlayerRoomStatus, this)
    phaserEvents.on(Event.DOCTOR_JOINED, this.doctorJoined, this)
    phaserEvents.on(Event.MAIN_DOOR_REMOVED, this.doctorOpenClinic, this)
    phaserEvents.on(Event.MAIN_DOOR_ADDED, this.doctorCloseClinic, this)
  }

  /**
   * method to join Colyseus' built-in LobbyRoom, which automatically notifies
   * connected clients whenever rooms with "realtime listing" have updates
   */
  async joinLobbyRoom() {
    this.lobby = await this.client.joinOrCreate(RoomType.LOBBY)
    console.log("joinOrCreatePublic RoomType.LOBBY lobby")

    this.lobby.onMessage('rooms', (rooms) => {
      store.dispatch(setAvailableRooms(rooms))
    })

    this.lobby.onMessage('+', ([roomId, room]) => {
      store.dispatch(addAvailableRooms({ roomId, room }))
    })

    this.lobby.onMessage('-', (roomId) => {
      store.dispatch(removeAvailableRooms(roomId))
    })
  }

  // method to join the public lobby
  async joinOrCreatePublic() {
    console.log("joinOrCreatePublic RoomType.PUBLIC skyoffice")
    this.room = await this.client.joinOrCreate(RoomType.PUBLIC)
    this.initialize()
  }

  // method to join a custom room
  async joinCustomById(roomId: string, password: string | null) {
    this.room = await this.client.joinById(roomId, { password })
    this.initialize()
  }

  // method to create a custom room
  async createCustom(roomData: IRoomData) {
    const { name, description, password, autoDispose } = roomData
    console.log("joinOrCreatePublic RoomType.CUSTOM custom")

    this.room = await this.client.create(RoomType.CUSTOM, {
      name,
      description,
      password,
      autoDispose,
    })
    this.initialize()
  }

  async logout(playerId:string) {
    //this.room.state.players.delete(playerId)
    console.log(this.mySessionId + " " + "logout")
    console.log("players", this.room.state.players)
    store.dispatch(setUserType(""))
    store.dispatch(setUserName(""))
    this.webRTC.removeVideoStream();
    this.room.state.players.delete(playerId);
    this.room.connection.close(1000,"logout");
  }

  // set up all network listeners before the game starts
  initialize() {
    if (!this.room) return

    this.lobby.leave()
    this.mySessionId = this.room.sessionId
    store.dispatch(setSessionId(this.room.sessionId))
    this.webRTC = new WebRTC(this.mySessionId, this)

    

    // new instance added to the players MapSchema
    this.room.state.players.onAdd = (player: IPlayer, key: string) => {
      if (key === this.mySessionId) return
      
      //this.room.state.myPlayerSessionId = this.mySessionId

      console.log("this.room.state.players.onAdd key: ", key)
      console.log("this.room.state.players.onAdd player: ", player)
      console.log("this.room.state.players.onAdd player playerType: ", player.playerType)
      console.log("this.room.state.players.onAdd player x: ", player.x)
      console.log("this.room.state.players.onAdd player y: ", player.y)
      console.log("this.room.state.players.onAdd player anim: ", player.anim)
      console.log("this.room.state.players.onAdd player isInsideClinicRoom: ", player.isInsideClinicRoom)
      
      /*this.room.clients.forEach((cli) => {
        if (cli.sessionId === message.clientId) {
          cli.send(Message.DISCONNECT_STREAM, client.sessionId)
        }
      })

      this.room.state.myPlayerSessionId = this.room.*/

      // track changes on every child object inside the players MapSchema
      player.onChange = (changes) => {
        changes.forEach((change) => {
          const { field, value } = change
          
          
          phaserEvents.emit(Event.PLAYER_UPDATED, field, value, key)

          if (field === 'name' && value !== '') {
            const game = phaserGame.scene.keys.game as Game
            //phaserEvents.emit(Event.PLAYER_JOINED, player, key)
            
            console.log("player joined object",player)
            game.handlePlayerJoined(player, key)
            store.dispatch(setPlayerNameMap({ id: key, name: value }))
            store.dispatch(pushPlayerJoinedMessage(value))
          }
        })
      }
    }

    // an instance removed from the players MapSchema
    this.room.state.players.onRemove = async(player: IPlayer, key: string) => {
      console.log("this.room.state.players.onRemove key: ", key)

      
      console.log("player type", player.playerType)
      // if (player.playerType == "Doctor") {//if removed player is doctor
      //   phaserEvents.emit(Event.MAIN_DOOR_ADDED, true)
      //   const resp = await ClinicAPI.closeClinic();
      //   console.log(resp)
      // }

      phaserEvents.emit(Event.PLAYER_LEFT, key)
      console.log("video left key", key)
      this.webRTC?.deleteVideoStream(key)
      this.webRTC?.deleteOnCalledVideoStream(key)
      store.dispatch(pushPlayerLeftMessage(player.name))
      store.dispatch(removePlayerNameMap(key))
    }

    // new instance added to the computers MapSchema
    this.room.state.computers.onAdd = (computer: IComputer, key: string) => {
      // track changes on every child object's connectedUser
      computer.connectedUser.onAdd = (item, index) => {
        phaserEvents.emit(Event.ITEM_USER_ADDED, item, key, ItemType.COMPUTER)
      }
      computer.connectedUser.onRemove = (item, index) => {
        phaserEvents.emit(Event.ITEM_USER_REMOVED, item, key, ItemType.COMPUTER)
      }
    }

    // new instance added to the whiteboards MapSchema
    this.room.state.whiteboards.onAdd = (whiteboard: IWhiteboard, key: string) => {
      store.dispatch(
        setWhiteboardUrls({
          whiteboardId: key,
          roomId: whiteboard.roomId,
        })
      )
      // track changes on every child object's connectedUser
      whiteboard.connectedUser.onAdd = (item, index) => {
        phaserEvents.emit(Event.ITEM_USER_ADDED, item, key, ItemType.WHITEBOARD)
      }
      whiteboard.connectedUser.onRemove = (item, index) => {
        phaserEvents.emit(Event.ITEM_USER_REMOVED, item, key, ItemType.WHITEBOARD)
      }
    }

    // new instance added to the chatMessages ArraySchema
    this.room.state.chatMessages.onAdd = (item, index) => {
      store.dispatch(pushChatMessage(item))
    }

    // when the server sends room data
    this.room.onMessage(Message.SEND_ROOM_DATA, (content) => {
      console.log("onMessageonMessage content!!", content)

      store.dispatch(setJoinedRoomData(content))
    })

    // when a user sends a message
    this.room.onMessage(Message.ADD_CHAT_MESSAGE, ({ clientId, content }) => {
      phaserEvents.emit(Event.UPDATE_DIALOG_BUBBLE, clientId, content)
    })

    // when a peer disconnects with myPeer
    this.room.onMessage(Message.DISCONNECT_STREAM, (clientId: string) => {
      console.log("disconnect video", clientId)
      console.log(clientId)
      this.webRTC?.deleteOnCalledVideoStream(clientId)
    })

    this.room.onMessage(Message.UPDATE_PLAYER_ROOM_STATUS, (message: { isInsideClinicRoom: boolean, x: number, y:number, clientId: string }) => {
      console.log("Network UPDATE_PLAYER_ROOM_STATUS isInsideClinicRoom", message.isInsideClinicRoom)
      console.log("Network UPDATE_PLAYER_ROOM_STATUS x", message.x)
      console.log("Network UPDATE_PLAYER_ROOM_STATUS y", message.y)
      console.log("Network UPDATE_PLAYER_ROOM_STATUS clientId", message.clientId)


      phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS_CLIENT, message.isInsideClinicRoom, message.x, message.y, message.clientId)

      //this.webRTC?.deleteOnCalledVideoStream(clientId)
    })

    this.room.onMessage(Message.MAIN_DOOR_REMOVED, (message: {joined: boolean}) => {
      console.log("Network REMOVE MAIN DOOR HERE")
      phaserEvents.emit(Event.MAIN_DOOR_REMOVED_CLIENT)
      //this.webRTC?.deleteOnCalledVideoStream(clientId)
    })

    this.room.onMessage(Message.MAIN_DOOR_ADDED, (message: {joined: boolean}) => {
      console.log("Network ADD MAIN DOOR HERE")
      phaserEvents.emit(Event.MAIN_DOOR_ADDED_CLIENT)
      //this.webRTC?.deleteOnCalledVideoStream(clientId)
    })

    this.room.onMessage(Message.DOCTOR_JOINED, (message: {joined: boolean}) => {

      console.log("room.onMessage(Message.DOCTOR_JOINED!!")
      phaserEvents.emit(Event.DOCTOR_JOINED_CLIENT, message.joined)
    })
    

    // when a computer user stops sharing screen
    this.room.onMessage(Message.STOP_SCREEN_SHARE, (clientId: string) => {
      const computerState = store.getState().computer
      computerState.shareScreenManager?.onUserLeft(clientId)
    })

    this.room.onMessage(Message.MOVE_PATIENTS_OUT_OF_CLINIC_ROOM, () => {
      console.log("MOVE_PATIENTS_OUT_OF_CLINIC_ROOM!!!!!!!!!!!!!!!", this.myPlayer)
     
      
      //if (this.myPlayer.playerType === 'Patient')
      const userType = store.getState().user.userType
      //move the patient out of the clinic room
      if (userType === 'Patient' && this.myPlayer.globalStatus === GlobalStatus.INDRROOM)/// && this.myPlayer.globalStatus === GlobalStatus.INDRROOM)
      {
        this.myPlayer.x = 855;
        this.myPlayer.y = 210;

        this.myPlayer.globalStatus = GlobalStatus.OUTSIDE
        this.myPlayer.isInsideClinicRoom = false
        this.myPlayer.move(855, 180) //update player text to be above player position
        this.myPlayer.refreshBody();

        //update player sprite position
        this.updatePlayer(855, 210, this.myPlayer.anims.currentAnim.key, this.myPlayer.playerType, this.myPlayer.isInsideClinicRoom)
      }

      console.log("MOVE_PATIENTS_OUT_OF_CLINIC_ROOM AFTER !!!!!!!!!!!!!!!", this.myPlayer)
    })
  }

  setMyPlayer(_myPlayer: MyPlayer, _playerType: string) {
    console.log("Network.ts setMyPlayer", _playerType)

    this.myPlayer = _myPlayer
    //this.myPlayer.playerType = _playerType

    console.log("Network.ts setMyPlayer", _myPlayer)
  }

  // method to register event listener and call back function when a item user added
  onChatMessageAdded(callback: (playerId: string, content: string) => void, context?: any) {
    phaserEvents.on(Event.UPDATE_DIALOG_BUBBLE, callback, context)
  }

  // method to register event listener and call back function when a item user added
  onItemUserAdded(
    callback: (playerId: string, key: string, itemType: ItemType) => void,
    context?: any
  ) {
    phaserEvents.on(Event.ITEM_USER_ADDED, callback, context)
  }

  // method to register event listener and call back function when a item user removed
  onItemUserRemoved(
    callback: (playerId: string, key: string, itemType: ItemType) => void,
    context?: any
  ) {
    phaserEvents.on(Event.ITEM_USER_REMOVED, callback, context)
  }

  // method to register event listener and call back function when a player joined
  onPlayerJoined(callback: (Player: IPlayer, key: string) => void, context?: any) {
    phaserEvents.on(Event.PLAYER_JOINED, callback, context)
  }
  
  onPlayerRoomStatusUpdated(callback: (isInsideClinicRoom: boolean, x: number, y:number, id: string) => void, context?: any) {
    phaserEvents.on(Event.UPDATE_PLAYER_ROOM_STATUS_CLIENT, callback, context)
  }

  onDoctorJoined(callback: (joined: boolean) => void, context?: any) {
    phaserEvents.on(Event.DOCTOR_JOINED_CLIENT, callback, context)
  }

  onMainDoorRemoved(callback: () => void, context?: any) {
    phaserEvents.on(Event.MAIN_DOOR_REMOVED_CLIENT, callback, context)
  }

  onMainDoorAdded(callback: () => void, context?: any) {
    phaserEvents.on(Event.MAIN_DOOR_ADDED_CLIENT, callback, context)
  }

  // method to register event listener and call back function when a player left
  onPlayerLeft(callback: (key: string) => void, context?: any) {
    phaserEvents.on(Event.PLAYER_LEFT, callback, context)
  }

  // method to register event listener and call back function when myPlayer is ready to connect
  onMyPlayerReady(callback: (key: string) => void, context?: any) {
    phaserEvents.on(Event.MY_PLAYER_READY, callback, context)
  }

  // method to register event listener and call back function when my video is connected
  onMyPlayerVideoConnected(callback: (key: string) => void, context?: any) {
    phaserEvents.on(Event.MY_PLAYER_VIDEO_CONNECTED, callback, context)
  }

  // method to register event listener and call back function when a player updated
  onPlayerUpdated(
    callback: (field: string, value: number | string, key: string) => void,
    context?: any
  ) {
    phaserEvents.on(Event.PLAYER_UPDATED, callback, context)
  }

  // method to send player updates to Colyseus server
  updatePlayer(currentX: number, currentY: number, currentAnim: string, playerType: string, isInsideClinicRoom: boolean) {
    console.log("updatePlayercurrentAnim!!!", currentAnim)
    this.room?.send(Message.UPDATE_PLAYER, { x: currentX, y: currentY, anim: currentAnim, playerType: playerType, isInsideClinicRoom: isInsideClinicRoom })

    
  }

  updatePlayerClinicRoomStatus(currentX: number, currentY: number, currentAnim: string, roomStatus: GlobalStatus) {
    this.room?.send(Message.UPDATE_PLAYER_CLINIC_ROOM_STATUS, { x: currentX, y: currentY, anim: currentAnim, rs: roomStatus })
  }

  movePatientsOutOfClinicRoom() {//currentX: number, currentY: number, currentAnim: string) {
    this.room?.send(Message.MOVE_PATIENTS_OUT_OF_CLINIC_ROOM)//, { x: currentX, y: currentY, anim: currentAnim })
  }

  // method to send player name to Colyseus server
  updatePlayerName(currentName: string) {
    this.room?.send(Message.UPDATE_PLAYER_NAME, { name: currentName })
  }

  updatePlayerRoomStatus(isInsideClinicRoom: boolean, x: number, y:number, id: string) {
    this.room?.send(Message.UPDATE_PLAYER_ROOM_STATUS, { isInsideClinicRoom: isInsideClinicRoom, x: x, y:y, clientId: id })
  }

  doctorJoined(joined: boolean) {
    this.myPlayer.isInsideClinicRoom = joined
    console.log("doctorJoined to clients!!")
    console.log("doctorJoined", this.myPlayer.isInsideClinicRoom)

    this.myPlayer.playerContainer.x = this.myPlayer.x
    this.myPlayer.playerContainer.y = this.myPlayer.y-30

    this.updatePlayer(this.myPlayer.x, this.myPlayer.y, this.myPlayer.anims.currentAnim.key, this.myPlayer.playerType, this.myPlayer.isInsideClinicRoom)
   ///// this.room?.send(Message.DOCTOR_JOINED, { joined: joined })
  }

  doctorOpenClinic(joined: boolean) {
    console.log("doctor open clinic")
    this.room?.send(Message.MAIN_DOOR_REMOVED)
  }

  doctorCloseClinic(joined: boolean) {
    console.log("doctor open clinic")
    this.room?.send(Message.MAIN_DOOR_ADDED)
  }

  // method to send ready-to-connect signal to Colyseus server
  readyToConnect() {
    this.room?.send(Message.READY_TO_CONNECT)
    phaserEvents.emit(Event.MY_PLAYER_READY)
  }

  // method to send ready-to-connect signal to Colyseus server
  videoConnected() {
    this.room?.send(Message.VIDEO_CONNECTED)
    phaserEvents.emit(Event.MY_PLAYER_VIDEO_CONNECTED)
  }

  // method to send stream-disconnection signal to Colyseus server
  playerStreamDisconnect(id: string) {
    this.room?.send(Message.DISCONNECT_STREAM, { clientId: id })
    //console.log()
    this.webRTC?.deleteVideoStream(id)
  }

  connectToComputer(id: string) {
    this.room?.send(Message.CONNECT_TO_COMPUTER, { computerId: id })
  }

  disconnectFromComputer(id: string) {
    this.room?.send(Message.DISCONNECT_FROM_COMPUTER, { computerId: id })
  }

  connectToWhiteboard(id: string) {
    this.room?.send(Message.CONNECT_TO_WHITEBOARD, { whiteboardId: id })
  }

  disconnectFromWhiteboard(id: string) {
    this.room?.send(Message.DISCONNECT_FROM_WHITEBOARD, { whiteboardId: id })
  }

  onStopScreenShare(id: string) {
    this.room?.send(Message.STOP_SCREEN_SHARE, { computerId: id })
  }

  addChatMessage(content: string) {
    this.room?.send(Message.ADD_CHAT_MESSAGE, { content: content })
  }
}
