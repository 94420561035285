import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import Adam from '../assets/Adam_login.png'
import Ash from '../assets/Ash_login.png'
import Lucy from '../assets/Lucy_login.png'
import Nancy from '../assets/Nancy_login.png'
import { useAppSelector, useAppDispatch } from '../hooks'
import { setLoggedIn } from '../stores/UserStore'
import { getAvatarString, getColorByString } from '../util'

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

import Coinbase from "../services/Coinbase"

import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers"

//import { InjectedConnector } from "@web3-react/walletlink-connector";
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
//import { WalletConnectConnector } from "@web3-react/walletlink-connector";

import { useApiRequest } from '../utils/api';

import ClinicAPI from '../services/api/ClinicAPI';
import { CLINIC_ID } from '../utils/constant'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import store from '../stores'
import { setNftVerifyState } from '../stores/UserStore'

import { phaserEvents, Event } from '../events/EventCenter'

SwiperCore.use([Navigation])


const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
 });





const Wrapper = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #222639;
  border-radius: 16px;
  padding: 36px 60px;
  box-shadow: 0px 0px 5px #0000006f;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const Title = styled.p`
  margin: 5px;
  font-size: 20px;
  color: #c2c2c2;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const ConnectionStatus = styled.div`
  font-size: 15px;
  color: white;
`

const RoomName = styled.div`
  max-width: 500px;
  max-height: 120px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  justify-content: center;

  h3 {
    font-size: 24px;
    color: #eee;
  }
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const RoomDescription = styled.div`
  max-width: 500px;
  max-height: 150px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  font-size: 16px;
  color: #c2c2c2;
  display: flex;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }
`

const Right = styled.div`
  width: 300px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Warning = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const avatars = [
  { name: 'adam', img: Adam },
  { name: 'ash', img: Ash },
  { name: 'lucy', img: Lucy },
  { name: 'nancy', img: Nancy },
]

// shuffle the avatars array
for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1))
  ;[avatars[i], avatars[j]] = [avatars[j], avatars[i]]
}

export default function VerifyNFTDialog() {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('')
  const [avatarIndex, setAvatarIndex] = useState<number>(0)
  const [nameFieldEmpty, setNameFieldEmpty] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const videoConnected = useAppSelector((state) => state.user?.videoConnected)
  const roomJoined = useAppSelector((state) => state.room.roomJoined)
  const roomName = useAppSelector((state) => state.room.roomName)
  const roomDescription = useAppSelector((state) => state.room.roomDescription)
  const game = phaserGame.scene.keys.game as Game
  const [alertContent, setAlertContent] = useState('')
  const [open, setOpen] = React.useState(false);

  const context = useWeb3React<Web3Provider>()

  const { active, activate, deactivate, library, chainId, account, } = context;

  const handleClose = () => {
    setOpen(false);
    store.dispatch(setNftVerifyState(1));
  };

  const CoinbaseWallet = new WalletLinkConnector({

    url: `https://mainnet.infura.io/v3/RmA884nyVPfGUV1Y`,
   
    appName: "Web3-react Demo",
   
    supportedChainIds: [1, 3, 4, 5, 42],
   
   });

  const { submit: verifyNft } = useApiRequest(ClinicAPI.verifyNft);

  const handleSubmit = () => {
    //const cb = new Coinbase();

    //console.log("URL:", cb.getAuthorizationURL());
    //window.location.replace(cb.getAuthorizationURL());
    //return;
  }

  useEffect(() => {

    async function verifyNftDo() {
      const resp = await verifyNft({
        clinicId: CLINIC_ID, walletAddress: account
      });


      if (resp.data.verified)
      {

        const game = phaserGame.scene.keys.game as Game
     
        game.removeMainDoor();
        phaserEvents.emit(Event.MAIN_DOOR_REMOVED, true)
        game.removeClinicRoomDoor();

        //setAlertContent("Clinic is opened until today 23:59:59!");
        //setOpen(true);


        phaserEvents.emit(Event.DOCTOR_JOINED, true)
        store.dispatch(setNftVerifyState(1))
      }
      else
      {
        setAlertContent(t('Nft.alert'));
        setOpen(true);
        
      }
    }

    if (account !== undefined)
    {
      verifyNftDo();
    }
  }, [account]);

  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  async function connect() {
    console.log("connect");
    try {
      await activate(injected)

      console.log("account: ", account)

      
    } catch (ex) {
      console.log(ex)
    }
  }

  async function disconnect() {
    try {
      deactivate()
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
      <Wrapper>
        <Title>{ t('Nft.title')}</Title>
        {/* onSubmit={handleSubmit}<RoomName>
          <h3>NFT Verification</h3>
        </RoomName> */}
        {/*<Bottom>
          <Button variant="contained" color="secondary" size="large" 
          onClick={() => handleSubmit() }>
            Login to Coinbase
          </Button>
        </Bottom>*/}

        {/*<div onClick={() => {activate(CoinbaseWallet)}}>
        </div>

      */}
        {!active && 
        <Button variant="contained" color="secondary" size="large" onClick={() => connect()}>
          { t('Nft.connect')}
        </Button>
        }

        {active ? <ConnectionStatus> { t('Nft.information')} <b>{account}</b></ConnectionStatus> : <ConnectionStatus></ConnectionStatus>}
        
        <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {alertContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  { t('Nft.ok')}
                </Button>
              </DialogActions>
            </Dialog>

      </Wrapper>
  )
}
