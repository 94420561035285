export const FWD_PHONE = '3123 3320';
export const MEDICONCEN_HOTLINE = '3897 4688';
export const RESPONSIVE_WINDOW_WIDTH = 1000;
export const primaryColor = '#e87722';
export const NUMBER_OF_MERGED_CELL_USAGE_HISTORY = 5;
export const DEFAULT_SYSTEM_ERROR = '00002';
export const DEFAULT_SUCCESS_CODE = '20001';
export const DEFAULT_NETWORK_ERROR = '99999';
export const ACCESS_TOKEN_EXPIRED_ERROR = '10003';
export const REFRESH_TOKEN_EXPIRED_ERROR = '10004';
export const NETWORK_ERROR = 'Network Error';
export const FWD_SEARCH_LINK = 'https://www.fwd.com.hk/search';
export const LANGUAGE_SWITCH_TC = '繁';
export const LANGUAGE_SWITCH_EN = 'EN';
export const MAXIMUM_NUMBER_OF_MEMBERS = 4;
export const HK_PHONE_LENGTH = 8;
export const TOTAL_FAMILY_MEMBER = 3;
export const XL_SCREEN = 1288;
export const LG_SCREEN = 1024;
export const MD_SCREEN = 992;
export const SM_SCREEN = 768;
export const XS_SCREEN = 576;

const BASED_AGE_GROUPS = ['18-29', '30-39', '40-49', '50-59', '60+'];
export const AGE_GROUPS = ['', ...BASED_AGE_GROUPS];
export const CHILD_AGE_GROUPS = ['', '<18', ...BASED_AGE_GROUPS];

export const CLINIC_ID = '599f123b-c076-11ec-a1d4-022482009426'