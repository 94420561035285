import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import Adam from '../assets/Adam_login.png'
import Ash from '../assets/Ash_login.png'
import Lucy from '../assets/Lucy_login.png'
import Nancy from '../assets/Nancy_login.png'
import Doctor from '../assets/Doctor_login.png'
import Doctor2 from '../assets/Doctor2_login.png'

import { useAppSelector, useAppDispatch } from '../hooks'
import { setLoggedIn } from '../stores/UserStore'
import { getAvatarString, getColorByString } from '../util'
import { useApiRequest } from '../utils/api';
import UserAPI from '../services/api/UserAPI';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

SwiperCore.use([Navigation])

const Wrapper = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #222639;
  border-radius: 16px;
  padding: 36px 60px;
  box-shadow: 0px 0px 5px #0000006f;
  @media (max-width: 500px) {
    padding: 10px 0px;
    width: 350px;
  }
  @media (max-height: 600px) {
  }
`

const Title = styled.p`
  margin: 5px;
  font-size: 20px;
  color: #c2c2c2;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  @media (max-height: 490px) {
    font-size: 14px;
  }
`

const RoomName = styled.div`
  max-width: 500px;
  max-height: 120px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  h3 {
    font-size: 24px;
    color: #eee;
  }

  @media (max-height: 490px) {
    font-size: 14px;
    h3 {
      font-size: 16px;
      color: #eee;
    }
  }
`

const RoomDescription = styled.div`
  max-width: 500px;
  max-height: 150px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  font-size: 16px;
  color: #c2c2c2;
  display: flex;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  @media (max-height: 490px) {
    font-size: 12px;
    max-height: 100px;
  }
`

const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
  @media (max-height: 490px) {
    font-size: 12px;
  }
`

const Content = styled.div`
  display: flex;
  margin: 36px 0;
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0;
  }
`

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 160px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
  }

  .swiper-slide {
    width: 160px;
    height: 220px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 95px;
    height: 136px;
    object-fit: contain;
  }

  @media (max-height: 600px) {
    .swiper-slide img {
      display: block;
      width: 55px;
      height: 76px;
      object-fit: contain;
    }

    .swiper-container {
      width: 90px;
      height: 120px;
      border-radius: 8px;
      overflow: hidden;
    }
  
    .swiper-slide {
      width: 90px;
      height: 120px;
      background: #dbdbe0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 500px) {
    margin-right: 0px;
  }
`

const Right = styled.div`
  width: 300px;
  @media (max-width: 500px) {
    width: 100%;
  }
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Warning = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  @media (max-width: 500px) {
    margin-top: 5px;
  }
`

const avatars = [
  { name: 'adam', img: Adam },
  { name: 'ash', img: Ash },
  { name: 'lucy', img: Lucy },
  { name: 'nancy', img: Nancy }
]

const avatarDoctor = [
  { name: 'doctor', img: Doctor },
  { name: 'doctor2', img: Doctor2 }
]

let currentAvatar = avatars;

// shuffle the avatars array
/*for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1))
  ;[avatars[i], avatars[j]] = [avatars[j], avatars[i]]
}*/

export default function LoginDialog() {
  const { t } = useTranslation();
  const playerName = useAppSelector((state) => state.user.playerName)
  const playerType = useAppSelector((state) => state.user.userType)
  const [name, setName] = useState<string>(playerName)
  const [currentAvatar, setCurrentAvatar] = useState(avatars)
  const [avatarIndex, setAvatarIndex] = useState<number>(0)
  const [nameFieldEmpty, setNameFieldEmpty] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const videoConnected = useAppSelector((state) => state.user.videoConnected)
  const [videoNotFound,setVideoNotFound] = useState(false)
  
  const playerAvatarIndex = useAppSelector((state) => state.user.playerAvatarIndex)
  const roomJoined = useAppSelector((state) => state.room.roomJoined)
  const roomName = useAppSelector((state) => state.room.roomName)
  const userName = useAppSelector((state) => state.user.userName)
  const roomDescription = useAppSelector((state) => state.room.roomDescription)
  const game = phaserGame.scene.keys.game as Game
  const { submit: updateUserSubmit } = useApiRequest(UserAPI.updateUser);
  const [alertContent, setAlertContent] = useState('')
  const [open, setOpen] = React.useState(false);
  
  useEffect(() => {
    if (playerType == "Doctor") {
      setCurrentAvatar(avatarDoctor)
    } else {
      setCurrentAvatar(avatars)
    }
    const timer = setTimeout(() => {
      if (videoConnected == false) {
        setVideoNotFound(true)
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, []);


  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (name === '') {
      setNameFieldEmpty(true)
    } else if (roomJoined) {

      if (userName)
      {
        const resp = await updateUserSubmit({
          userName: userName, displayName: name, avatarName: avatars[avatarIndex].name
        });

        console.log(resp);

        if (resp.data.code)
        {
          setAlertContent(resp.data.message);
          setOpen(true);

          return;
        }
      }
      
      game.registerKeys()
      game.myPlayer.setPlayerName(name)
      console.log("avatar name", currentAvatar[avatarIndex].name)
      game.myPlayer.setPlayerTexture(currentAvatar[avatarIndex].name)
      game.network.readyToConnect()
      
      dispatch(setLoggedIn(true))
    }
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title>{ t('loginDialog.title')}</Title>
      <RoomName>
        <Avatar style={{ background: getColorByString(roomName) }}>
          {getAvatarString(t('loginDialog.lobby'))}
        </Avatar>
        <h3>{t('loginDialog.lobby')}</h3>
      </RoomName>
      <RoomDescription>
        <ArrowRightIcon /> { t('loginDialog.welcome')}
      </RoomDescription>
      <Content>
        <Left>
          <SubTitle>{ t('loginDialog.select')}</SubTitle>
          <Swiper
            // install Swiper modules
            initialSlide={playerAvatarIndex}
            //activeSlideKey={playerAvatarIndex}
            navigation
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={(swiper) => {
              setAvatarIndex(swiper.activeIndex)
            }}
          >
            {currentAvatar.map((avatar) => (
              <SwiperSlide key={avatar.name}>
                <img src={avatar.img} alt={avatar.name} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Left>
        <Right>
          <TextField
            autoFocus
            fullWidth
            label={ t('loginDialog.name')}
            variant="outlined"
            color="secondary"
            value={name}
            error={nameFieldEmpty}
            helperText={nameFieldEmpty && 'Name is required'}
            onInput={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />
          {(!videoConnected && !videoNotFound) && (
            <Warning>
              <Alert variant="outlined" severity="warning">
                <AlertTitle>{ t('loginDialog.alertTitle')}</AlertTitle>
                {  t('loginDialog.connectProgress')}
              </Alert>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  game.network.webRTC?.getUserMedia()
                }}
              >
                {  t('loginDialog.connectWebcam') }
              </Button>
            </Warning>
          )}

        {(!videoConnected && videoNotFound) && (
            <Warning>
              <Alert variant="outlined" severity="warning">
                <AlertTitle>{ t('loginDialog.alertTitle')}</AlertTitle>
                {  t('loginDialog.connectFailed')}
              </Alert>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  game.network.webRTC?.getUserMedia()
                }}
              >
                {  t('loginDialog.connectWebcam') }
              </Button>
            </Warning>
          )}

          {videoConnected && (
            <Warning>
              <Alert variant="outlined">{ t('loginDialog.connectSuccess')}</Alert>
            </Warning>
          )}
        </Right>
      </Content>
      <Bottom>
        <Button variant="contained" color="secondary" size="large" type="submit">
          {  t('loginDialog.join') }
        </Button>
      </Bottom>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {  t('loginDialog.ok') }
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  )
}
