import { ItemType } from '../../../types/Items'
import Item from './Item'
import store from '../stores'
import i18n from "../i18n"
export default class VendingMachine extends Item {
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.itemType = ItemType.VENDINGMACHINE
  }

  onOverlapDialog(_playerType: string) {
    const userType = store.getState().user.userType

    if (userType === 'Patient')
    this.setDialogBox(i18n.t('game.booking'))
  }
}
