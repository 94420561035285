import Phaser from 'phaser'

export const phaserEvents = new Phaser.Events.EventEmitter()

export enum Event {
  PLAYER_JOINED = 'player-joined',
  PLAYER_UPDATED = 'player-updated',
  PLAYER_LEFT = 'player-left',
  PLAYER_DISCONNECTED = 'player-disconnected',
  MY_PLAYER_READY = 'my-player-ready',
  MY_PLAYER_NAME_CHANGE = 'my-player-name-change',
  MY_PLAYER_TEXTURE_CHANGE = 'my-player-texture-change',
  MY_PLAYER_VIDEO_CONNECTED = 'my-player-video-connected',
  ITEM_USER_ADDED = 'item-user-added',
  ITEM_USER_REMOVED = 'item-user-removed',
  UPDATE_DIALOG_BUBBLE = 'update-dialog-bubble',
  UPDATE_PLAYER_ROOM_STATUS = 'update-player-room-status',
  UPDATE_PLAYER_ROOM_STATUS_CLIENT = 'update-player-room-status-client',
  DOCTOR_JOINED = 'doctor-joined',
  DOCTOR_JOINED_CLIENT = 'doctor-joined-client',
  MAIN_DOOR_REMOVED = 'main-door-removed',
  MAIN_DOOR_REMOVED_CLIENT = 'main-door-removed-client',
  MAIN_DOOR_ADDED = 'main-door-added',
  MAIN_DOOR_ADDED_CLIENT = 'main-door-added-client'
}
