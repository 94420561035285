import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sanitizeId } from '../util'
import { BackgroundMode } from '../../../types/BackgroundMode'

import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'

export function getInitialBackgroundMode() {
  const currentHour = new Date().getHours()
  return currentHour > 6 && currentHour <= 18 ? BackgroundMode.DAY : BackgroundMode.NIGHT
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    backgroundMode: getInitialBackgroundMode(),
    sessionId: '',
    videoConnected: false,
    loggedIn: false,
    userName: '',
    playerName: '',
    playerAvatarIndex: -1,
    playerLang: 'zh',
    userType: '',
    mainScreenState: 0, // 0: Main option screen; 1: Doctor login screen; 2: Register screen; 3: Forgot password screen; 
    nftVerifyState: 0, // 0: Not verified; 2: Verifying (Show pop up); 1: Verified
    mobileState: 0,
    playerNameMap: new Map<string, string>(),
  },
  reducers: {
    toggleBackgroundMode: (state) => {
      const newMode =
        state.backgroundMode === BackgroundMode.DAY ? BackgroundMode.NIGHT : BackgroundMode.DAY

      state.backgroundMode = newMode
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
      //bootstrap.changeBackgroundMode(newMode)
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },
    setVideoConnected: (state, action: PayloadAction<boolean>) => {
      state.videoConnected = action.payload
    },
    setMainScreenStateState: (state, action: PayloadAction<integer>) => {
      state.mainScreenState = action.payload
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload
    },
    setUserType: (state, action: PayloadAction<string>) => {
      state.userType = action.payload
    },
    setPlayerName: (state, action: PayloadAction<string>) => {
      state.playerName = action.payload
    },
    setPlayerAvatarIndex: (state, action: PayloadAction<integer>) => {
      state.playerAvatarIndex = action.payload
    },
    setNftVerifyState: (state, action: PayloadAction<integer>) => {
      state.nftVerifyState = action.payload
    },
    setMobileState: (state, action: PayloadAction<integer>) => {
      state.mobileState = action.payload
    },
    setPlayerNameMap: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.playerNameMap.set(sanitizeId(action.payload.id), action.payload.name)
    },
    removePlayerNameMap: (state, action: PayloadAction<string>) => {
      state.playerNameMap.delete(sanitizeId(action.payload))
    },
    setPlayerLang: (state, action: PayloadAction<string>) => {
      state.playerLang = action.payload
    },

    /*function getUserType(state, action: PayloadAction<string>) : string {

    }
    getUserType(state, action: PayloadAction<string>) : string => {
      return state.userType
    }*/
  },
})

export const {
  toggleBackgroundMode,
  setSessionId,
  setVideoConnected,
  setLoggedIn,
  setUserName,
  setUserType,
  setPlayerName,
  setPlayerAvatarIndex,
  setNftVerifyState,
  setMobileState,
  setMainScreenStateState,
  setPlayerNameMap,
  removePlayerNameMap,
  setPlayerLang
  //getUserType
} = userSlice.actions

export default userSlice.reducer
