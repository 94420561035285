//import AuthAPI, { RefreshTokenResponse } from '@src/services/api/AuthAPI';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { DEFAULT_SYSTEM_ERROR, ACCESS_TOKEN_EXPIRED_ERROR, REFRESH_TOKEN_EXPIRED_ERROR } from './constant';
import { ErrorCode } from '../types/error';
//import { useGlobalContext } from './store';
//import { useRouter } from 'next/dist/client/router';
//import { RoutePath } from '@src/config/RoutePath';
import { BackendResponseFailType, ResponseSuccessType } from '../types/commons';

export const formatApiResult = ({ data, status }: AxiosResponse<unknown>) => ({
  data,
  status: Number(status >= 200 && status < 300),
});

type InferResponseDataType<T> = T extends Promise<ResponseSuccessType<infer U>> ? U : unknown;

// interface A<T> extends ResponseType<T | null> {
//   error: Error | null;
// }

export const useApiRequest = <T extends (...args: any) => Promise<ResponseSuccessType<unknown>>>(
  targetApiRequest: T,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<InferResponseDataType<ReturnType<T>> | null>(null);
  //const { auth, setAuth, setErrorDialog, setIsProgressBarShow } = useGlobalContext();
  //const router = useRouter();

  const submit = useCallback(
    async (
      ...args: Parameters<T>
    ): Promise<ResponseSuccessType<InferResponseDataType<ReturnType<T>>> & { error: ErrorCode | null }> => {
      try {
        //setIsLoading(true);
        //setIsProgressBarShow(true);
        const resp = (await targetApiRequest(...args)) as ResponseSuccessType<InferResponseDataType<ReturnType<T>>>;
        setData(resp.data);
        return { error: null, ...resp };
      } catch (error: unknown) {
        const errorCode = (error as AxiosResponse<BackendResponseFailType>)?.data.code ?? DEFAULT_SYSTEM_ERROR;

        /*
        // check if token expired
        if (errorCode === ACCESS_TOKEN_EXPIRED_ERROR) {
          // refresh token
          if (auth?.refreshToken) {
            const refreshTokenResp = await AuthAPI.refreshToken({ refreshToken: auth.refreshToken });
            const { accessToken, accessTokenExpireAt } = refreshTokenResp.data as RefreshTokenResponse;
            setAuth({
              accessToken,
              accessTokenExpireAt,
              refreshToken: auth.refreshToken,
              refreshTokenExpireAt: auth.refreshTokenExpireAt,
            });
          } else {
            router.push(RoutePath.LOGIN);
          }
          // retry
          return await submit(...args);
        }*/

        /*setErrorDialog({
          visible: true,
          errorCode: errorCode as ErrorCode,
        });*/
        setIsError(true);
        return { error: errorCode as ErrorCode, data: null, status: 0 };
      } finally {
        setIsLoading(false);
        //setIsProgressBarShow(false);
      }
    },
    [],
  );

  return {
    isLoading,
    isError,
    data,
    submit,
  };
};

export const formDataHeader = {
  'Content-Type': 'multipart/form-data',
  Accept: '*/*',
};
