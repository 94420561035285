import RequestService, { basePath } from '../RequestService';

export type RegisterRequestBodyType = {
    userName?: string;
    password?: string;
    displayName?: string;
    clinicId?: string;
  };

export type LoginRequestBodyType = {
    userName?: string;
    password?: string;
  };

export type UpdateUserRequestBodyType = {
    userName?: string;
    displayName?: string;
    avatarName?: string;
}

export type ForgetPasswordRequestBodyType = {
  userName?: string;
}

export type ResetPasswordRequestBodyType = {
  resetToken?: string;
  password?: string;
}

const register = (body: Partial<RegisterRequestBodyType>) => {
  const url = `${basePath}/user/register`;
  return RequestService.post(url, body);
}

const login = (body: Partial<LoginRequestBodyType>) => {
    const url = `${basePath}/user/login`;
    return RequestService.post(url, body);
}

const updateUser = (body: Partial<UpdateUserRequestBodyType>) => {
  const url = `${basePath}/user/updateUser`;
  return RequestService.post(url, body);
}

const forgetPassword = (body: Partial<ForgetPasswordRequestBodyType>) => {
  const url = `${basePath}/user/forgetPassword`;
  return RequestService.post(url, body);
}

const resetPassword = (body: Partial<ResetPasswordRequestBodyType>) => {
  const url = `${basePath}/user/resetPassword`;
  return RequestService.post(url, body);
}

const UserAPI = {
    login,
    register,
    updateUser,
    forgetPassword,
    resetPassword
  };
  
export default UserAPI;