import { formatApiResult } from '../utils/api';
import { getPersistedData } from '../utils/common';
import { DEFAULT_NETWORK_ERROR, NETWORK_ERROR } from '../utils/constant';
import axios, { AxiosRequestConfig } from 'axios';

export const basePath = process.env.REACT_APP_BACKEND_URL;

const RequestService = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    //'Access-Control-Allow-Origin': '*',
    //'Test': 'hehe',
    Accept: 'application/json',
  },
});
/*
RequestService.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    const result = getPersistedData('auth');
    if (result) {
      config.headers = {
        Authorization: 'Bearer ' + result.accessToken,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);*/

RequestService.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return formatApiResult(response);
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.message === NETWORK_ERROR) {
      error.response = {
        data: {
          code: DEFAULT_NETWORK_ERROR,
        },
        status: 400,
      };
    }
    return Promise.reject(formatApiResult(error.response));
  },
);

export default RequestService;
