import Phaser, { Scene } from 'phaser'
import PlayerSelector from './PlayerSelector'
import { PlayerBehavior } from '../../../types/PlayerBehavior'
import { GlobalStatus } from '../../../types/GlobalStatus'
import { sittingShiftData } from './Player'
import Player from './Player'
import Network from '../services/Network'
import Chair from '../items/Chair'
import Computer from '../items/Computer'
import Whiteboard from '../items/Whiteboard'
import ShopDoor from '../items/ShopDoor'
import i18n from '../i18n'


import { phaserEvents, Event } from '../events/EventCenter'
import store from '../stores'
import { pushPlayerJoinedMessage } from '../stores/ChatStore'
import { setNftVerifyState, setMobileState } from '../stores/UserStore'
import { ItemType } from '../../../types/Items'
import MainDoor from '../items/MainDoor'

import { ResponseSuccessType } from '../utils/common';
import { CLINIC_ID } from '../utils/constant';
import { useApiRequest, formatApiResult } from '../utils/api';
import BookingAPI from '../services/api/BookingAPI';
import { constants } from 'buffer'

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'
import { throws } from 'assert'
import DoctorChair from '../items/DoctorChair'
import { positions } from '@mui/system'


export type GetBookingResultType = {
  passcode?: string;
}

interface GetBookingResponse {
  passcode: string;
}

export default class MyPlayer extends Player {
  private playContainerBody: Phaser.Physics.Arcade.Body
  private chairOnSit?: Chair
  //public playerType: string = 'Patient'
  public sDoor: ShopDoor

  public touchPosX: number;
  public touchPosY: number;
  
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    playType: string,
    isInsideClinicRoom: boolean,
    frame?: string | number
  ) {
    super(scene, x, y, texture, id, playType, isInsideClinicRoom, frame)
    this.playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body
  }

  setPlayerName(name: string) {
    this.playerName.setText(name)
    phaserEvents.emit(Event.MY_PLAYER_NAME_CHANGE, name)
    store.dispatch(pushPlayerJoinedMessage(name))
  }

  setPlayerTexture(texture: string) {
    const game = phaserGame.scene.keys.game as Game
    this.playerTexture = texture
    this.anims.play(`${this.playerTexture}_idle_down`, true)
    phaserEvents.emit(Event.MY_PLAYER_TEXTURE_CHANGE, this.x, this.y, this.anims.currentAnim.key)
    
  }

  public move(x: number, y: number) {
    this.playerContainer.setPosition(x, y)
    this.playContainerBody.update(100)
  }

  public async DoorInteraction(playerSelector: PlayerSelector, network: Network) {
    const item = playerSelector.selectedItem
    const shopDoor = item as ShopDoor
    const userType = store.getState().user.userType

    //this.sDoor = shopDoor 

    let playermovelocX
    let msg
    // **-pending-**
    // This is to control what msg shows in ShopDoor Object. 
    // Later should rename to consultation room + detect direction in tiledMap
    let doorMsgCode
    let drDoorCanEnter=false

    if (this.globalStatus === GlobalStatus.OUTSIDE){
      drDoorCanEnter=false

      const game = phaserGame.scene.keys.game as Game
      const canEnter = game.checkPatientCanEnterClinicRoom()

      console.log("canEnter", canEnter)
      
      if (!this.videoConnected) {
        drDoorCanEnter=false
        alert(i18n.t("game.door.appointment.camera.failed"))
        this.globalStatus = GlobalStatus.OUTSIDE
        this.isInsideClinicRoom = false
      }
      else if (!canEnter)
      {
        drDoorCanEnter=false
        alert(i18n.t('game.door.appointment.patient.failed'))
        this.globalStatus = GlobalStatus.OUTSIDE
        this.isInsideClinicRoom = false
      }  
      else
      {
        let inputedPassCode= prompt(i18n.t('game.door.appointment.patient.prompt'), "vAacEL5JE");
        const resp = (await BookingAPI.getBookingByPasscode({passcode: inputedPassCode, clinicId: CLINIC_ID}));

        console.log("Booking API resp", resp);

        

        const now = new Date();

        console.log("now.toUTCString()", now.toUTCString())
        console.log("new Date(resp.data.bookingStartTime).toUTCString()", new Date(resp.data.bookingStartTime).toUTCString())
        console.log("new Date(resp.data.bookingEndTime).toUTCString()", new Date(resp.data.bookingEndTime).toUTCString())

        console.log("now.toUTCString() >= new Date(resp.data.bookingStartTime).toUTCString() && now.toUTCString()", now >= new Date(resp.data.bookingStartTime))

        console.log("now.toUTCString() <= new Date(resp.data.bookingEndTime).toUTCString())", now <= new Date(resp.data.bookingEndTime))

        //**-- Check to see the door passcode--**
        if (inputedPassCode!==resp.data.passcode)
        {
          drDoorCanEnter=false
          alert(i18n.t('doctor.alert'))
          this.globalStatus = GlobalStatus.OUTSIDE
          this.isInsideClinicRoom = false
        }
        // else if (!(now>= new Date(resp.data.bookingStartTime) && now <= new Date(resp.data.bookingEndTime))) {
        //   drDoorCanEnter=false
        //   alert("This booking is not for this timeslot")
        //   this.globalStatus = GlobalStatus.OUTSIDE
        //   this.isInsideClinicRoom = false
        // }
        else
        {
        //if (inputedPassCode=="11"){

          drDoorCanEnter=true
          playermovelocX=32
          msg=i18n.t('doctor.enter')
          //**--Door msg show already go inside--**              
          doorMsgCode=1
          this.globalStatus = GlobalStatus.INDRROOM
          this.isInsideClinicRoom = true

          network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
        } 
      }


    }else{
      //**--i.e GlobalStatus.INDRROOM--**
        drDoorCanEnter=true            
        playermovelocX=-32
        msg=i18n.t('doctor.quit')
      //**--Door msg show already go out--**              
        doorMsgCode=0
        this.globalStatus = GlobalStatus.OUTSIDE      
        this.isInsideClinicRoom = false
    }


  //*---Move the player outside or inside the room--*
  if(drDoorCanEnter){
    this.scene.time.addEvent({
      delay: 10,
      callback: () => {
        // update character velocity and position
          this.setVelocity(0, 0)
          this.setPosition(
            870 + playermovelocX,
            208 - 0
          )
          // also update playerNameContainer velocity and position
          this.playContainerBody.setVelocity(0, 0)
          this.playerContainer.setPosition(
            870 + playermovelocX,
            208 - 30
          )

      },
      loop: false,
    })

    // set up new dialog when player go inside the room
   

    console.log("B4 phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS", this.playerId)
    phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS, doorMsgCode === 1, 870 + playermovelocX, 208, this.playerId)
    
    if (userType === 'Doctor') {
      const game = phaserGame.scene.keys.game as Game
      game.movePlayersOutofClinicRoom();
    }

    console.log("AFTER phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS", this.playerId)
    //network.updatePlayer(this.x, this.y, this.anims.currentAnim.key)

    return

    }
  }

//Main Character will listen to the keyboard event
  async update(
    playerSelector: PlayerSelector,
    cursors: Phaser.Types.Input.Keyboard.CursorKeys,
    keyE: Phaser.Input.Keyboard.Key,
    keyV: Phaser.Input.Keyboard.Key,
    keyK: Phaser.Input.Keyboard.Key,
    keyB: Phaser.Input.Keyboard.Key,
    keyO: Phaser.Input.Keyboard.Key,      
    network: Network,
    pointer: Phaser.Input.Pointer,
    camera:any
  ) {

    if (!cursors) return

    if (pointer.isDown) {
      this.scene.input.activePointer.updateWorldPoint(this.scene.cameras.main);
      this.scene.input.setPollAlways();
      let realPosX = pointer.worldX;
      let realposY = pointer.worldY;
      this.touchPosY = realposY;
      this.touchPosX = realPosX;

      let vx = 0;
      let vy = 0;
      let speed = 200;

      if (this.touchPosX - this.body.position.x >= 30 && !this.body.onCollide) {

        vx += speed;
  
        this.play(`${this.playerTexture}_run_right`, true)

      } else {
      }
  
      if (this.touchPosX - this.body.position.x <= -20 && !this.body.onCollide) {
        vx -= speed;
  
        this.play(`${this.playerTexture}_run_left`, true)

  
      }else {
      }
  
      if (this.touchPosY - this.body.position.y <= -30 && !this.body.onCollide) {
        vy -= speed
        this.setDepth(this.y) //change player.depth if player.y changes
  
        this.play(`${this.playerTexture}_run_up`, true)
        

      } else {
      }
  
      if (this.touchPosY - this.body.position.y >= 20 && !this.body.onCollide) {
        
        vy += speed
        this.setDepth(this.y) //change player.depth if player.y changes
  
        this.play(`${this.playerTexture}_run_down`, true)

      } 

      
      this.setVelocity(vx, vy)
      this.body.velocity.setLength(speed)
      this.playerContainer.setX(this.body.position.x + 7)
      this.playerContainer.setY(this.body.position.y - 44)

      network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
      const item = playerSelector.selectedItem
      const userType = store.getState().user.userType
      switch (item?.itemType) {
        case ItemType.VENDINGMACHINE:
          // hacky and hard-coded, but leaving it as is for now
          if(this.playerType == 'Patient')
          store.dispatch(setMobileState(1))
          break
        case ItemType.MAINDOOR:
            if (this.playerType == 'Doctor') {
                store.dispatch(setMobileState(2))
            } 
            break;
        case ItemType.SHOPDOOR:
          if (this.playerType == 'Doctor') {
            if (!this.videoConnected) {
              alert(i18n.t("game.door.appointment.camera.failed"))
              this.globalStatus = GlobalStatus.OUTSIDE
              this.isInsideClinicRoom = false
            } else if (this.globalStatus === GlobalStatus.INDRROOM) {
              store.dispatch(setMobileState(4))
            } else {
              store.dispatch(setMobileState(2))
            }
          } else {
            store.dispatch(setMobileState(4))
          }
          break;
        case ItemType.DOCTORCHAIR:
          if (this.playerType == 'Doctor')
          store.dispatch(setMobileState(3))
          break;
      }
      return
    }

    
    


    

    const item = playerSelector.selectedItem

    if (Phaser.Input.Keyboard.JustDown(keyB)) {
      switch (item?.itemType) {
        case ItemType.COMPUTER:
          const computer = item as Computer
          computer.openDialog(this.playerId, network)
          break
        case ItemType.WHITEBOARD:
          const whiteboard = item as Whiteboard
          whiteboard.openDialog(network)
          break
        case ItemType.VENDINGMACHINE:
          // hacky and hard-coded, but leaving it as is for now
          if(this.playerType == 'Patient')
          window.open('https://chinalife.mediconcen.com/', '_blank')
          break

      }
    }
    

    // if (Phaser.Input.Keyboard.JustDown(keyO)) {
    //   switch (item?.itemType) {

    //     case ItemType.SHOPDOOR:
    //       // here kelvin
    //       //window.open('http://www.mediconcen.com/', '_blank')
    //       prompt("To open this door. Please enter your passcode here", "Your Secret code");


    //       break

    //   }
    // }

    const userType = store.getState().user.userType
    switch (this.playerBehavior) {
      case PlayerBehavior.IDLE:
        //const userType = store.getState().user.userType
        if (item?.itemType === ItemType.EXITDOOR) {
          if (Phaser.Input.Keyboard.JustDown(keyE)) {
            console.log(this.playerName.text)
            console.log(this.playerTexture)
            window.location.href = 'http://52.221.30.178:8080/?name=' + this.playerName.text + "&&texture=" + this.playerTexture // reload current tab to mccbox
          }
        }

        // if press E in front of selected chair
        if (userType === 'Doctor' && item?.itemType === ItemType.DOCTORCHAIR) {
          const chairItem = item as DoctorChair

          if (Phaser.Input.Keyboard.JustDown(keyV))
          {
            console.log("doctor make call")
            const game = phaserGame.scene.keys.game as Game
            game.makeCall()
          }
          if (Phaser.Input.Keyboard.JustDown(keyE))
          {
            /**
             * move player to the chair and play sit animation
             * a delay is called to wait for player movement (from previous velocity) to end
             * as the player tends to move one more frame before sitting down causing player
             * not sitting at the center of the chair
             */
            this.scene.time.addEvent({
              delay: 10,
              callback: () => {
                // update character velocity and position
                this.setVelocity(0, 0)
                if (chairItem.itemDirection) {
                  this.setPosition(
                    chairItem.x + sittingShiftData[chairItem.itemDirection][0],
                    chairItem.y + sittingShiftData[chairItem.itemDirection][1]
                  ).setDepth(chairItem.depth + sittingShiftData[chairItem.itemDirection][2])
                  // also update playerNameContainer velocity and position
                  this.playContainerBody.setVelocity(0, 0)
                  this.playerContainer.setPosition(
                    chairItem.x + sittingShiftData[chairItem.itemDirection][0],
                    chairItem.y + sittingShiftData[chairItem.itemDirection][1] - 30
                  )
                }

                this.play(`${this.playerTexture}_sit_${chairItem.itemDirection}`, true)
                playerSelector.selectedItem = undefined
                if (chairItem.itemDirection === 'up') {
                  playerSelector.setPosition(this.x, this.y - this.height)
                } else {
                  playerSelector.setPosition(0, 0)
                }
                // send new location and anim to server
                network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
              },
              loop: false,
            })
            // set up new dialog as player sits down
            chairItem.clearDialogBox()

            const userType = store.getState().user.userType

            if (userType === 'Doctor')
              chairItem.setDialogBox('Press E to leave')

            this.chairOnSit = chairItem
            this.playerBehavior = PlayerBehavior.SITTING
            return
          }
          else if (Phaser.Input.Keyboard.JustDown(keyK))
          {
            const game = phaserGame.scene.keys.game as Game
            
            game.movePlayersOutofClinicRoom();

            //chairItem.setDialogBox('hah')
          }
        }



        if (Phaser.Input.Keyboard.JustDown(keyO)) {
          switch (item?.itemType) {
            case ItemType.MAINDOOR:
              if (userType === 'Doctor')
              {
                //const game = phaserGame.scene.keys.game as Game
            
                //game.movePlayersOutofClinicRoom();
                if (!this.videoConnected) {
                    alert(i18n.t("game.door.appointment.camera.failed"))
                    this.globalStatus = GlobalStatus.OUTSIDE
                    this.isInsideClinicRoom = false
                } else {
                  store.dispatch(setNftVerifyState(2))
                }

              }

              break

            case ItemType.SHOPDOOR:

                const shopDoor = item as ShopDoor
              console.log("shopdoor",shopDoor)
                //this.sDoor = shopDoor 

                let playermovelocX
                let msg
                // **-pending-**
                // This is to control what msg shows in ShopDoor Object. 
                // Later should rename to consultation room + detect direction in tiledMap
                let doorMsgCode
                let drDoorCanEnter=false

                if (this.globalStatus === GlobalStatus.OUTSIDE){
                  drDoorCanEnter=false

                  const game = phaserGame.scene.keys.game as Game
                  const canEnter = game.checkPatientCanEnterClinicRoom()

                  console.log("canEnter", canEnter)
                  
                  if (!this.videoConnected) {
                    drDoorCanEnter=false
                    alert(i18n.t("game.door.appointment.camera.failed"))
                    this.globalStatus = GlobalStatus.OUTSIDE
                    this.isInsideClinicRoom = false
                  }
                  else if (!canEnter)
                  {
                    drDoorCanEnter=false
                    alert(i18n.t('game.door.appointment.patient.failed'))
                    this.globalStatus = GlobalStatus.OUTSIDE
                    this.isInsideClinicRoom = false
                  }  
                  else
                  {
                    let inputedPassCode= prompt(i18n.t('game.door.appointment.patient.prompt'), "vAacEL5JE");
                    const resp = (await BookingAPI.getBookingByPasscode({passcode: inputedPassCode, clinicId: CLINIC_ID}));

                    console.log("Booking API resp", resp);

                    

                    const now = new Date();

                    console.log("now.toUTCString()", now.toUTCString())
                    console.log("new Date(resp.data.bookingStartTime).toUTCString()", new Date(resp.data.bookingStartTime).toUTCString())
                    console.log("new Date(resp.data.bookingEndTime).toUTCString()", new Date(resp.data.bookingEndTime).toUTCString())

                    console.log("now.toUTCString() >= new Date(resp.data.bookingStartTime).toUTCString() && now.toUTCString()", now >= new Date(resp.data.bookingStartTime))

                    console.log("now.toUTCString() <= new Date(resp.data.bookingEndTime).toUTCString())", now <= new Date(resp.data.bookingEndTime))

                    //**-- Check to see the door passcode--**
                    if (inputedPassCode!==resp.data.passcode)
                    {
                      drDoorCanEnter=false
                      alert(i18n.t('doctor.alert'))
                      this.globalStatus = GlobalStatus.OUTSIDE
                      this.isInsideClinicRoom = false
                    }
                    // else if (!(now>= new Date(resp.data.bookingStartTime) && now <= new Date(resp.data.bookingEndTime))) {
                    //   drDoorCanEnter=false
                    //   alert("This booking is not for this timeslot")
                    //   this.globalStatus = GlobalStatus.OUTSIDE
                    //   this.isInsideClinicRoom = false
                    // }
                    else
                    {
                    //if (inputedPassCode=="11"){

                      drDoorCanEnter=true
                      playermovelocX=32
                      msg=i18n.t('doctor.enter')
                      //**--Door msg show already go inside--**              
                      doorMsgCode=1
                      this.globalStatus = GlobalStatus.INDRROOM
                      this.isInsideClinicRoom = true

                      network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
                    } 
                  }


                }else{
                  //**--i.e GlobalStatus.INDRROOM--**
                    drDoorCanEnter=true            
                    playermovelocX=-32
                    msg=i18n.t('doctor.quit')
                  //**--Door msg show already go out--**              
                    doorMsgCode=0
                    this.globalStatus = GlobalStatus.OUTSIDE      
                    this.isInsideClinicRoom = false
                }


              //*---Move the player outside or inside the room--*
              if(drDoorCanEnter){
                this.scene.time.addEvent({
                  delay: 10,
                  callback: () => {
                    // update character velocity and position
                      this.setVelocity(0, 0)
                      this.setPosition(
                        shopDoor.x + playermovelocX,
                        shopDoor.y - 0
                      )
                      // also update playerNameContainer velocity and position
                      this.playContainerBody.setVelocity(0, 0)
                      this.playerContainer.setPosition(
                        shopDoor.x + playermovelocX,
                        shopDoor.y - 30
                      )

                  },
                  loop: false,
                })

                // set up new dialog when player go inside the room
                shopDoor.clearDialogBox()
                shopDoor.setDialogBox(msg)

                //this.globalStatus = GlobalStatus.INDRROOM
                shopDoor.setGlobalStautus(doorMsgCode)

                console.log("B4 phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS", this.playerId)
                phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS, doorMsgCode === 1, shopDoor.x + playermovelocX, shopDoor.y, this.playerId)
                
                if (userType === 'Doctor') {
                  const game = phaserGame.scene.keys.game as Game
                  game.movePlayersOutofClinicRoom();
                }

                console.log("AFTER phaserEvents.emit(Event.UPDATE_PLAYER_ROOM_STATUS", this.playerId)
                //network.updatePlayer(this.x, this.y, this.anims.currentAnim.key)

                return

                }

                break
              }

            }



            //**--This part is to control the event of up/down/left/right
            const speed = 200
            let vx = 0
            let vy = 0
            if (cursors.left?.isDown) vx -= speed
            if (cursors.right?.isDown) vx += speed
            if (cursors.up?.isDown) {
              vy -= speed
              this.setDepth(this.y) //change player.depth if player.y changes
            }
            if (cursors.down?.isDown) {
              vy += speed
              this.setDepth(this.y) //change player.depth if player.y changes
            }
            // update character velocity
            this.setVelocity(vx, vy)
            this.body.velocity.setLength(speed)
            // also update playerNameContainer velocity
            
            //change from this
            //this.playContainerBody.setVelocity(vx, vy)
            //this.playContainerBody.velocity.setLength(speed)
            
            //to this code, so that the player name containet will stick to player character when player stuck in collider
            this.playerContainer.setX(this.body.position.x + 7)
            this.playerContainer.setY(this.body.position.y - 44)

            // update animation according to velocity and send new location and anim to server
            if (vx !== 0 || vy !== 0) network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
            if (vx > 0) {
              this.play(`${this.playerTexture}_run_right`, true)
            } else if (vx < 0) {
              this.play(`${this.playerTexture}_run_left`, true)
            } else if (vy > 0) {
              this.play(`${this.playerTexture}_run_down`, true)
            } else if (vy < 0) {
              this.play(`${this.playerTexture}_run_up`, true)
            } else {
              const parts = this.anims.currentAnim.key.split('_')
              parts[1] = 'idle'
              const newAnim = parts.join('_')
              // this prevents idle animation keeps getting called
              if (this.anims.currentAnim.key !== newAnim) {
                this.play(parts.join('_'), true)
                // send new location and anim to server
                network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
              }
            }
            break

          case PlayerBehavior.SITTING:
            //const userType = store.getState().user.userType
            // back to idle if player press E while sitting
            if (userType === 'Doctor' && Phaser.Input.Keyboard.JustDown(keyE)) {
              const parts = this.anims.currentAnim.key.split('_')
              parts[1] = 'idle'
              this.play(parts.join('_'), true)
              this.playerBehavior = PlayerBehavior.IDLE
              this.chairOnSit?.clearDialogBox()
              playerSelector.setPosition(this.x, this.y)
              playerSelector.update(this, cursors)
              network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, this.playerType, this.isInsideClinicRoom)
            }
            break

    }
  }
}

declare global {
  namespace Phaser.GameObjects {
    interface GameObjectFactory {
      myPlayer(x: number, y: number, texture: string, id: string, playerType: string, isInsideClinicRoom: boolean, frame?: string | number): MyPlayer
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register(
  'myPlayer',
  function (
    this: Phaser.GameObjects.GameObjectFactory,
    x: number,
    y: number,
    texture: string,
    id: string,
    playType: string,
    isInsideClinicRoom: boolean,
    frame?: string | number
  ) {
    const sprite = new MyPlayer(this.scene, x, y, texture, id, playType, isInsideClinicRoom, frame)

    this.displayList.add(sprite)
    this.updateList.add(sprite)

    this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)

    const collisionScale = [0.5, 0.2]
    sprite.body
      .setSize(sprite.width * collisionScale[0], sprite.height * collisionScale[1])
      .setOffset(
        sprite.width * (1 - collisionScale[0]) * 0.5,
        sprite.height * (1 - collisionScale[1])
      )

    return sprite
  }
)
