import Phaser from 'phaser'
import Network from '../services/Network'
import { BackgroundMode } from '../../../types/BackgroundMode'
import store from '../stores'
import { setRoomJoined, setLobbyJoined } from '../stores/RoomStore'
import { setLoggedIn, setPlayerAvatarIndex, setUserName, setUserType,  } from '../stores/UserStore'
import MyPlayer from '../characters/MyPlayer'
import Player from '../characters/Player'

export default class Bootstrap extends Phaser.Scene {
  network!: Network

  constructor() {
    super('bootstrap')
  }

  preload() {
    this.load.image('backdrop_grad', 'assets/background/backdrop_gradient.png')

    this.load.tilemapTiledJSON('tilemap', 'assets/map/map.json')
    this.load.spritesheet('tiles_wall', 'assets/map/FloorAndGround.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.spritesheet('chairs', 'assets/items/chair.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.spritesheet('doctorchair', 'assets/items/chair.png', {
      frameWidth: 32,
      frameHeight: 64,
    })
    this.load.spritesheet('computers', 'assets/items/computer.png', {
      frameWidth: 96,
      frameHeight: 64,
    })
    
    this.load.spritesheet('vendingmachines', 'assets/items/vendingmachine.png', {
      frameWidth: 48,
      frameHeight: 72,
    })
    this.load.spritesheet('nursebookings', 'assets/items/nursebooking.png', {
      frameWidth: 48,
      frameHeight: 72,
    })
    this.load.spritesheet('office', 'assets/items/Modern_Office_Black_Shadow.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.spritesheet('basement', 'assets/items/Basement.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.spritesheet('generic', 'assets/items/Generic.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    
    //Load the door here
    this.load.spritesheet('shopdoor', 'assets/items/DoorRPGSmall.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.spritesheet('sidedoor', 'assets/items/DoorRPGSmall.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    this.load.spritesheet('maindoor', 'assets/items/DoorRPGSmall.png', {
      frameWidth: 32,
      frameHeight: 32,
    })
    //Load the cupboardAndDoll here
    this.load.spritesheet('cupboard', 'assets/items/DoorRPGSmall.png', {
      frameWidth: 32,
      frameHeight: 32,
    })

    this.load.spritesheet('cl', 'assets/items/CL.png', {
      frameWidth: 32,
      frameHeight: 32,
    })


    this.load.spritesheet('adam', 'assets/character/adam.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
    this.load.spritesheet('ash', 'assets/character/ash.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
    this.load.spritesheet('lucy', 'assets/character/lucy.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
    this.load.spritesheet('nancy', 'assets/character/nancy.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
    this.load.spritesheet('doctor', 'assets/character/doctor.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
    this.load.spritesheet('doctor2', 'assets/character/doctor_2.png', {
      frameWidth: 32,
      frameHeight: 48,
    })
  }

  init() {
    this.network = new Network()
  }

  create() {
    this.launchBackground()
  }

  private launchBackground() {
    this.scene.launch('background')
  }

  launchGame(hideMainDoor: boolean, playerType: string) {
    this.network.webRTC?.checkPreviousPermission()
    this.scene.launch('game', {
      network: this.network,
      hideMainDoor: hideMainDoor,
      playerType: playerType
    })

    // update Redux state
    store.dispatch(setRoomJoined(true))
  }

  logout(playerId:string) {
    this.scene.stop('game')
    // update Redux state
    store.dispatch(setRoomJoined(false))
    store.dispatch(setLoggedIn(false))
    store.dispatch(setUserType(""))
    store.dispatch(setUserName(""))
    store.dispatch(setPlayerAvatarIndex(-1))
    this.network.logout(playerId)

  }

  // changeBackgroundMode(backgroundMode: BackgroundMode) {
  //   this.scene.stop('background')
  //   this.launchBackground(backgroundMode)
  // }
}
