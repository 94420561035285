export enum Message {
  UPDATE_PLAYER,
  UPDATE_PLAYER_NAME,
  READY_TO_CONNECT,
  DISCONNECT_STREAM,
  CONNECT_TO_COMPUTER,
  DISCONNECT_FROM_COMPUTER,
  STOP_SCREEN_SHARE,
  CONNECT_TO_WHITEBOARD,
  DISCONNECT_FROM_WHITEBOARD,
  VIDEO_CONNECTED,
  ADD_CHAT_MESSAGE,
  SEND_ROOM_DATA,
  MOVE_PATIENTS_OUT_OF_CLINIC_ROOM,
  UPDATE_PLAYER_ROOM_STATUS,
  UPDATE_PLAYER_CLINIC_ROOM_STATUS,
  DOCTOR_JOINED,
  MAIN_DOOR_REMOVED,
  MAIN_DOOR_ADDED,
}
