import React, { useState, useEffect } from 'react'
import logo from '../assets/mcc-logo-onMain.jpg'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import LinearProgress from '@mui/material/LinearProgress'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import { CustomRoomTable } from './CustomRoomTable'
import { CreateRoomForm } from './CreateRoomForm'
import { useAppSelector } from '../hooks'

import phaserGame from '../PhaserGame'
import Bootstrap from '../scenes/Bootstrap'

import store from '../stores'
import { setMainScreenStateState, setUserType, setPlayerLang  } from '../stores/UserStore'
import { constants } from 'buffer'

import { useApiRequest } from '../utils/api';
import ClinicAssetAPI from '../services/api/ClinicAssetAPI';

import { CLINIC_ID } from '../utils/constant';
import Game from '../scenes/Game';
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

const Backdrop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  
`

const Wrapper = styled.div`
  background: #222639;
  border-radius: 16px;
  padding: 36px 60px;
  box-shadow: 0px 0px 5px #0000006f;
  @media (max-width: 500px) {
    padding: 0px 0px;
  }
`

const CustomRoomWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .tip {
    font-size: 18px;
  }
`

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const Title = styled.h1`
  font-size: 24px;
  color: #eee;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

const SelectWrapper = styled.div`
  position: absolute;
  top: 1px;
  right: 0px;
  z-index: 999;
  @media (max-width: 500px) {
    position: absolute;
    top: -5px;
    right: -2%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 8px;
    height: 120px;
  }

  @media (max-width: 500px) {
    img {
      height: 100px;
    }
  }
`

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #33ac96;
  }
`

const ProgressBar = styled(LinearProgress)`
  width: 360px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function RoomSelectionDialog() {
  const { t, i18n  } = useTranslation();
  const [alertContent, setAlertContent] = useState('')
  const [open, setOpen] = React.useState(false);
  const [lng, setLng] = useState('zh')
  const [lang, setLang] = useState('zh')
  const [showCustomRoom, setShowCustomRoom] = useState(false)
  const [showCreateRoomForm, setShowCreateRoomForm] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const lobbyJoined = useAppSelector((state) => state.room.lobbyJoined)
  const [lobbyLoading, setLobbyLoading] = useState(false)
  const { submit: checkAssetStatus } = useApiRequest(ClinicAssetAPI.checkAssetStatus);
  
  useEffect(() => {
    if (isMobile == true) {
      setAlertContent(t('room.select.warning'));
      setOpen(true);
    }
    const langDefault = store.getState().user.playerLang;
    if (langDefault == 'zh') {
      setLng('zh')
      setLang('zh')
    } else {
      setLng('en')
      setLang('en')
    }
  }, [])

  const handleClose = () => {
    setOpen(false);
  };

  /* const handleConnect = async() => {
    if (lobbyJoined) {
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
      bootstrap.network
        .joinOrCreatePublic()
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error))

      const caresp = await checkAssetStatus({
        clinicId: CLINIC_ID,
        assetType: 'MAIN_DOOR'
      });

      console.log("caresp.data", caresp.data);

      if (caresp.data.assetStatus === 0)
      {
        const game = phaserGame.scene.keys.game as Game
     
        game.removeMainDoor();
      }

      store.dispatch(setUserType('Patient'));
    } else {
      setShowSnackbar(true)
    }
  } */

  const handleConnect = async () => {
    setLobbyLoading(true); //give loading so that it will not generate another user login
    if (lobbyJoined && !lobbyLoading) {
      console.log(lobbyLoading)
      const caresp = await checkAssetStatus({
        clinicId: CLINIC_ID,
        assetType: 'MAIN_DOOR'
      });

      console.log("caresp",caresp.data)
      /*const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
      bootstrap.network
        .joinOrCreatePublic()
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error))*/

      if(caresp.data.assetStatus === 0)
      {
        const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
        bootstrap.network
          .joinOrCreatePublic()
          .then(() => 
          {
            bootstrap.launchGame(true, "Patient")
            const game = phaserGame.scene.keys.game as Game
     
            game.removeMainDoor();
            setLobbyLoading(false)
          })
        .catch((error) => console.error(error))
      }
      else
      {
        const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
        bootstrap.network
          .joinOrCreatePublic()
          .then(() => {
            bootstrap.launchGame(false, "Patient");
            setLobbyLoading(false)
          })
          .catch((error) => console.error(error))
      }

      store.dispatch(setUserType('Patient'));
    } else {
      setShowSnackbar(true)
    }
  }

  const switchLanguage = (event: SelectChangeEvent) => {
    const selectedLang = event.target.value;
    if (selectedLang == "zh") {
      i18n.changeLanguage("zh")
      setLng("zh")
      setLang("zh")
      store.dispatch(setPlayerLang('zh'))
    } else {
      i18n.changeLanguage("en")
      setLng("en")
      setLang("en")
      store.dispatch(setPlayerLang('en'))
    }
  }

  const handleDoctorLogin = () => {
    store.dispatch(setMainScreenStateState(1));
  }

  const handleRegister = () => {
    store.dispatch(setMainScreenStateState(2));
  }

  const handleForgotPassword = () => {
    store.dispatch(setMainScreenStateState(3));
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(false)
        }}
      >
        <Alert
          severity="error"
          variant="outlined"
          // overwrites the dark theme on render
          style={{ background: '#fdeded', color: '#7d4747' }}
        >
          { t('room.select.try.connect')}
        </Alert>
      </Snackbar>
      <Backdrop>
        <Wrapper>
          {showCreateRoomForm ? (
            <CustomRoomWrapper>
              <Title>{ t('room.select.create.custom')}</Title>
              <BackButtonWrapper>
                <IconButton onClick={() => setShowCreateRoomForm(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </BackButtonWrapper>
              <CreateRoomForm />
            </CustomRoomWrapper>
          ) : showCustomRoom ? (
            <CustomRoomWrapper>
              <Title>
              { t('room.select.custom')}
                <Tooltip
                  title="We update the results in realtime, no refresh needed!"
                  placement="top"
                >
                  <IconButton>
                    <HelpOutlineIcon className="tip" />
                  </IconButton>
                </Tooltip>
              </Title>
              <BackButtonWrapper>
                <IconButton onClick={() => setShowCustomRoom(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </BackButtonWrapper>
              <CustomRoomTable />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowCreateRoomForm(true)}
              >

                  {t('room.select.create.custom')}
              </Button>
            </CustomRoomWrapper>
          ) : (
            <>
              <Title>
                {t('room.select.title')}
              </Title>
              <SelectWrapper>
                <FormControl sx={{ m: 1, minWidth: 90, fontSize: '12px' }}>
                  <Select
                        sx={{
                          boxSizing: 'border-box', width: '80px', height: '35px', borderRadius: '7px', border: '1px solid #ABABAB', fontSize: '12px', fontFamily: 'Poppins',
                          "fieldset" : {
                            border: 'none',
                            outline: 'none' 
                          }
                        }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: '10px',
                          marginTop: '-5px',
                          background: '#222639',
                          border: '1px solid #FFFFFF',
                          width: '81px',
                          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
                          fontSize: '12px'
                        }
                      },
                      MenuListProps: {
                        sx: {
                          fontSize: '12px'
                        }
                      }
                    }}
                    IconComponent = {KeyboardArrowDownIcon}
                    defaultValue={"zh"}
                    onChange={switchLanguage}
                    value={lang}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                      >
                    <MenuItem value={"en"} sx={{fontSize:'12px', fontFamily:'Poppins'}}>ENG</MenuItem>
                    <MenuItem value={"zh"} sx={{fontSize:'12px', fontFamily:'Poppins'}}>中文</MenuItem>
                  </Select>
                </FormControl>
                
              </SelectWrapper>
              <Content>
                  <img src={logo} alt="logo" />
                    {lng == "zh" &&
                      <>
                        <Button variant="contained" color="secondary" onClick={handleConnect} style={{width:"104px",height:"36.5px"}}>
                          {t('room.select.patient.login')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleDoctorLogin} style={{width:"104px",height:"36.5px"}}>
                          {t('room.select.doctor.login')}
                        </Button>
                      </>
                    }

                    {lng == "en" &&
                      <>
                        <Button variant="contained" color="secondary" onClick={handleConnect} style={{width:"200px",height:"40px"}}>
                          {t('room.select.patient.login')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleDoctorLogin} style={{width:"200px",height:"40px"}}>
                          {t('room.select.doctor.login')}
                        </Button>
                      </>
                    }
                
                {/*<Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => (lobbyJoined ? setShowCustomRoom(true) : setShowSnackbar(true))}
                >
                  Create/find custom rooms
                </Button>*/}

              </Content>
              {/* <Bottom>
              <Link onClick={handleForgotPassword}>{t('room.select.forgotpassword')}</Link>
              <Box sx={{ ml: 2 }} />
              <Button variant="contained" color="secondary" onClick={switchLanguage}>
                {lang}
              </Button>
              <Box sx={{ mr: 5 }} />
                <Link onClick={handleRegister}>{t('room.select.register')}</Link>
              </Bottom> */}
            </>
          )}
        </Wrapper>
        {!lobbyJoined && (
          <ProgressBarWrapper>
            <h3> {t('room.select.connect')}</h3>
            <ProgressBar color="secondary" />
          </ProgressBarWrapper>
        )}
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
