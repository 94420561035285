import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import { useAppSelector, useAppDispatch } from '../hooks'
import { setLoggedIn, setMobileState } from '../stores/UserStore'
import { getAvatarString, getColorByString } from '../util'

import phaserGame from '../PhaserGame'
import Game from '../scenes/Game'

import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import store from '../stores'
import dots from '../assets/dots.png'
import avatarReel from '../assets/characters-reel.png'
import { setNftVerifyState } from '../stores/UserStore'

import { phaserEvents, Event } from '../events/EventCenter'


SwiperCore.use([Navigation])


const Wrapper = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 335px;
  height: 400px;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  border: 4px solid #000000;
  box-shadow: 0px 0px 5px #0000006f;
  display:flex;
  flex-direction:column;
  z-index:999;
`

const Title = styled.p`
  margin: 5px;
  font-size: 24px;
  color: black;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TopClose = styled.div`
  width: 100%;
  display: flex;
`

const Close = styled.button`
  margin-left: auto;
  border: 0px;
  font-size :18px;
  font-weight: 700;
  background-color: transparent;
`
const AvatarImg = styled.img`
  padding: 0px;
  gap: 18px;
  margin: auto;
  width: 222px;
  margin-left: auto;
  margin-right: auto;
`
const Dots = styled.img`
  padding: 0px;
  gap: 18px;

  width: 222px;
  height: 6px;
  margin-top:10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
`
const ButtonBook = styled.button`
  font-size: 20px;
  font-weight:500;
  background: linear-gradient(90deg, rgb(66, 233, 192) 2.84%, rgb(41, 174, 232) 100%);
  gap: 9px;
  border-radius: 50px;
  width: 270px;
  height: 47px;
  color: white;
  margin-bottom: 15px;
  border:0px;
`


export default function DoctorMenuDialog() {
  const { t } = useTranslation();
  const game = phaserGame.scene.keys.game as Game
  const [open, setOpen] = React.useState(false);

  const context = useWeb3React<Web3Provider>()

  const { active, activate, deactivate, library, chainId, account, } = context;

  const handleKick = () => {
    //setOpen(false);
    game.movePlayersOutofClinicRoom();
    store.dispatch(setMobileState(0));
  };

  const handleCall = () => {
    game.makeCall();
    store.dispatch(setMobileState(0));
  }

  const handleClose = () => {
    //setOpen(false);
    store.dispatch(setMobileState(0));
  };


  return (
      <Wrapper>
        <TopClose>
          <Close onClick={handleClose}>X</Close>
        </TopClose>
      <Title>{t('popup.doctor.title')} </Title>
        <Dots src={dots}>

        </Dots>
        <Bottom>
            <ButtonBook onClick={handleKick} >
              {t('popup.doctor.action')}
            </ButtonBook>
        </Bottom>
        <Bottom>
            <ButtonBook onClick={handleCall}>
              {t('popup.doctor.action_2')}
            </ButtonBook>
        </Bottom>
        <Bottom>
            <ButtonBook onClick={handleClose}>
              {t('popup.doctor.cancel')}
            </ButtonBook>
        </Bottom>
        <AvatarImg src={avatarReel}></AvatarImg>

      </Wrapper>
  )
}
