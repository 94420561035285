import Phaser from 'phaser'
import Player from './Player'
import MyPlayer from './MyPlayer'
import { sittingShiftData } from './Player'
import WebRTC from '../web/WebRTC'
import { Event, phaserEvents } from '../events/EventCenter'

import { PlayerBehavior } from '../../../types/PlayerBehavior'
import { GlobalStatus } from '../../../types/GlobalStatus'

export default class OtherPlayer extends Phaser.Physics.Arcade.Sprite{
  private targetPosition: [number, number]
  private lastUpdateTimestamp?: number
  private connectionBufferTime = 1000
  private connected = false
  private playContainerBody: Phaser.Physics.Arcade.Body
  private myPlayer?: MyPlayer

  playerId: string
  playerTexture: string
  globalStatus = GlobalStatus.OUTSIDE
  playerBehavior = PlayerBehavior.IDLE
  readyToConnect = true
  videoConnected = false
  playerName: Phaser.GameObjects.Text
  public playerContainer: Phaser.GameObjects.Container
  private playerDialogBubble: Phaser.GameObjects.Container
  private timeoutID?: number
  public playerType: string = 'Patient'
  public isInsideClinicRoom: boolean = false 


  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    id: string,
    name: string,
    playType: string,
    isInsideClinicRoom: boolean,
    frame?: string | number
  ) {
    //super(scene, x, y, texture, id, playType, isInsideClinicRoom, frame)
    super(scene, x, y, texture, frame)

    console.log("player joined object tex",texture)

    this.playerId = id
    this.playerTexture = texture
    this.playerType = playType
    this.isInsideClinicRoom = isInsideClinicRoom

    console.log("player joined object type",playType)

    this.setDepth(this.y)

    if (this.playerTexture.includes("_")) {
      this.anims.play(`${this.playerTexture}`, true)
    } else {
      this.anims.play(`${this.playerTexture}_idle_down`, true)
    }
    
    this.playerContainer = this.scene.add.container(this.x, this.y - 30).setDepth(5000)

    console.log("player joined object x", this.x)
    
    console.log("player joined object y",this.y-30)

    console.log("player joined object type",this.playerContainer)

    // add dialogBubble to playerContainer
    this.playerDialogBubble = this.scene.add.container(0, 0).setDepth(5000)
    this.playerContainer.add(this.playerDialogBubble)

    // add playerName to playerContainer
    this.playerName = this.scene.add
      .text(0, 0, '')
      .setFontFamily('Arial')
      .setFontSize(12)
      .setColor('#000000')
      .setOrigin(0.5)
    this.playerContainer.add(this.playerName)

    this.scene.physics.world.enable(this.playerContainer)
    const playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body
    const collisionScale = [0.5, 0.2]
    playContainerBody
      .setSize(this.width * collisionScale[0], this.height * collisionScale[1])
      .setOffset(-8, this.height * (1 - collisionScale[1]) + 6)


    this.targetPosition = [x, y]

    this.playerName.setText(name)
    this.playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body
  }

  makeCall(myPlayer: MyPlayer, webRTC: WebRTC) {
    this.myPlayer = myPlayer
    const myPlayerId = myPlayer.playerId

    console.log("OtherPlayer makeCall myPlayerId", myPlayerId)
    console.log("OtherPlayer makeCall this.playerId", this.playerId)

    console.log("make call", myPlayer)

    console.log("make call connected", this.connected)

    console.log("make call connected", this.readyToConnect)

    console.log("make call connected", this.connectionBufferTime)

    if (
      !this.connected &&
      myPlayer.readyToConnect &&
      this.readyToConnect &&
      myPlayer.videoConnected
    ) {
      console.log("OtherPlayer makeCall CALL NOW!!!!")
      webRTC.connectToNewUser(this.playerId)
      webRTC.setCloseCallButton()
      this.connected = true
      this.connectionBufferTime = 0
    }
  }

  closeCall(myPlayer: MyPlayer, webRTC: WebRTC) {
    this.myPlayer = myPlayer
    const myPlayerId = myPlayer.playerId

    //webRTC.connectToNewUser(this.playerId)
    //webRTC.deleteVideoStream(myPlayer.playerId)
    //webRTC.deleteOnCalledVideoStream(this.playerId)
    phaserEvents.emit(Event.PLAYER_DISCONNECTED, this.playerId)
    this.videoConnected = false;
    this.readyToConnect = true
    this.connectionBufferTime = 0
    this.connected = false
  }

  public move(x: number, y: number) {
    this.playerContainer.setPosition(x, y)
    this.playContainerBody.update(100)
  }

  updateDialogBubble(content: string) {
    this.clearDialogBubble()

    // preprocessing for dialog bubble text (maximum 70 characters)
    const dialogBubbleText = content.length <= 70 ? content : content.substring(0, 70).concat('...')

    const innerText = this.scene.add
      .text(0, 0, dialogBubbleText, { wordWrap: { width: 165, useAdvancedWrap: true } })
      .setFontFamily('Arial')
      .setFontSize(12)
      .setColor('#000000')
      .setOrigin(0.5)

    // set dialogBox slightly larger than the text in it
    const innerTextHeight = innerText.height
    const innerTextWidth = innerText.width

    innerText.setY(-innerTextHeight / 2 - this.playerName.height / 2)
    const dialogBoxWidth = innerTextWidth + 10
    const dialogBoxHeight = innerTextHeight + 3
    const dialogBoxX = innerText.x - innerTextWidth / 2 - 5
    const dialogBoxY = innerText.y - innerTextHeight / 2 - 2

    this.playerDialogBubble.add(
      this.scene.add
        .graphics()
        .fillStyle(0xffffff, 1)
        .fillRoundedRect(dialogBoxX, dialogBoxY, dialogBoxWidth, dialogBoxHeight, 3)
        .lineStyle(1, 0x000000, 1)
        .strokeRoundedRect(dialogBoxX, dialogBoxY, dialogBoxWidth, dialogBoxHeight, 3)
    )
    this.playerDialogBubble.add(innerText)

    // After 6 seconds, clear the dialog bubble
    this.timeoutID = window.setTimeout(() => {
      this.clearDialogBubble()
    }, 6000)
  }

  private clearDialogBubble() {
    clearTimeout(this.timeoutID)
    this.playerDialogBubble.removeAll(true)
  }

  updateOtherPlayer(field: string, value: number | string | boolean) {
    switch (field) {
      case 'name':
        if (typeof value === 'string') {
          this.playerName.setText(value)
        }
        break

      case 'x':
        if (typeof value === 'number') {
          this.targetPosition[0] = value
        }
        break

      case 'y':
        if (typeof value === 'number') {
          this.targetPosition[1] = value
        }
        break

      case 'anim':
        if (typeof value === 'string') {
          this.anims.play(value, true)
        }
        break

      case 'readyToConnect':
        if (typeof value === 'boolean') {
          this.readyToConnect = value
        }
        break

      case 'videoConnected':
        if (typeof value === 'boolean') {
          this.videoConnected = value
        }
        break

      case 'playerType':
          if (typeof value === 'string') {
            this.playerType = value
          }
          break
        
      case 'isInsideClinicRoom':
          if (typeof value === 'boolean') {
            this.isInsideClinicRoom = value
          }
          break
    }
  }

  destroy(fromScene?: boolean) {
    this.playerContainer.destroy()

    super.destroy(fromScene)
  }

  /** preUpdate is called every frame for every game object. */
  preUpdate(t: number, dt: number) {
    super.preUpdate(t, dt)

    // if Phaser has not updated the canvas (when the game tab is not active) for more than 1 sec
    // directly snap player to their current locations
    if (this.lastUpdateTimestamp && t - this.lastUpdateTimestamp > 750) {
      this.lastUpdateTimestamp = t
      this.x = this.targetPosition[0]
      this.y = this.targetPosition[1]
      this.playerContainer.x = this.targetPosition[0]
      this.playerContainer.y = this.targetPosition[1] - 30
      return
    }

    this.lastUpdateTimestamp = t
    this.setDepth(this.y) // change player.depth based on player.y
    if (this.anims.currentAnim != null) {
      const animParts = this.anims.currentAnim.key.split('_')
      const animState = animParts[1]
      if (animState === 'sit') {
        const animDir = animParts[2]
        const sittingShift = sittingShiftData[animDir]
        if (sittingShift) {
          // set hardcoded depth (differs between directions) if player sits down
          this.setDepth(this.depth + sittingShiftData[animDir][2])
        }
      }
    }

    const speed = 200 // speed is in unit of pixels per second
    const delta = (speed / 1000) * dt // minimum distance that a player can move in a frame (dt is in unit of ms)
    let dx = this.targetPosition[0] - this.x
    let dy = this.targetPosition[1] - this.y

    // if the player is close enough to the target position, directly snap the player to that position
    if (Math.abs(dx) < delta) {
      this.x = this.targetPosition[0]
      this.playerContainer.x = this.targetPosition[0]
      dx = 0
    }
    if (Math.abs(dy) < delta) {
      this.y = this.targetPosition[1]
      this.playerContainer.y = this.targetPosition[1] - 30
      dy = 0
    }

    // if the player is still far from target position, impose a constant velocity towards it
    let vx = 0
    let vy = 0
    if (dx > 0) vx += speed
    else if (dx < 0) vx -= speed
    if (dy > 0) vy += speed
    else if (dy < 0) vy -= speed

    // update character velocity
    this.setVelocity(vx, vy)
    this.body.velocity.setLength(speed)
    // also update playerNameContainer velocity
    this.playContainerBody.setVelocity(vx, vy)
    this.playContainerBody.velocity.setLength(speed)

    // while currently connected with myPlayer
    // if myPlayer and the otherPlayer stop overlapping, delete video stream
    // this.connectionBufferTime += dt
    // if (
    //   this.connected &&
    //   !this.body.embedded &&
    //   this.body.touching.none &&
    //   this.connectionBufferTime >= 750
    // ) {
    //   if (this.x < 610 && this.y > 515 && this.myPlayer!.x < 610 && this.myPlayer!.y > 515) return
    //   phaserEvents.emit(Event.PLAYER_DISCONNECTED, this.playerId)
    //   this.connectionBufferTime = 0
    //   this.connected = false
    // }
  }
}

declare global {
  namespace Phaser.GameObjects {
    interface GameObjectFactory {
      otherPlayer(
        x: number,
        y: number,
        texture: string,
        id: string,
        name: string,
        playType: string,
        isInsideClinicRoom: boolean,
        frame?: string | number
      ): OtherPlayer
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register(
  'otherPlayer',
  function (
    this: Phaser.GameObjects.GameObjectFactory,
    x: number,
    y: number,
    texture: string,
    id: string,
    name: string,
    playType: string,
    isInsideClinicRoom: boolean,
    frame?: string | number
  ) {
    const sprite = new OtherPlayer(this.scene, x, y, texture, id, name, playType, isInsideClinicRoom, frame)

    this.displayList.add(sprite)
    this.updateList.add(sprite)

    this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)

    const collisionScale = [6, 4]
    sprite.body
      .setSize(sprite.width * collisionScale[0], sprite.height * collisionScale[1])
      .setOffset(
        sprite.width * (1 - collisionScale[0]) * 0.5,
        sprite.height * (1 - collisionScale[1]) * 0.5 + 17
      )

    return sprite
  }
)
